<template>
<v-container id="FornituraContainer" class="pa-7">
        <v-row class="d-flex" justify="center" align="center">
            <v-card class="card_home_container info_pagamento_dialog_card">
                <v-container id="BollettaInterattivaFornituraCostiCard5Dt">
                    <v-card-title>
                        <v-container>
                        <v-row>
                        <v-col cols="8">
                            <v-row align="left">
                                <v-col cols="12" align="left">
                                <p class="text-h5 TitleFornituraCard5Dt">{{translateLabel('message.bolletta_interattiva_template5_costi_title')}}</p>
                                <p v-if="(documentData.bolletta_interattiva_esponi_periodo_fatturazione == 1) && documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio" class="text-h5 item_consumi_periodo">{{ documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio}}</p>
                                </v-col>
                            </v-row>

                            <v-row class="FirstContentBollettaFornituraCostiCard5Dt" >
                                <v-col cols="6"></v-col>
                                <v-col justify="center" align="center">
                                    <p class="invoices_totale_pagare">{{$t('message.bolletta_interattiva_template5_costi_totale_da_pagare')}}</p>
                                    
                                    <div v-if="documentData.dati_pagamento_fields.doc_payment_debt.format == 'amount'" class="font-weight-bold px-4 doc_payment_debt_value">
                                        {{ documentData.dati_pagamento_fields.doc_payment_debt.value | formatAmount(documentData.dati_pagamento_fields.doc_payment_debt.format_show, documentData.currency_sigle) }}
                                    </div>
                                    <div v-else-if="documentData.dati_pagamento_fields.doc_payment_debt.format == 'number'" class="font-weight-bold px-4 doc_payment_debt_value">
                                        {{ documentData.dati_pagamento_fields.doc_payment_debt.value | formatNumber(documentData.dati_pagamento_fields.doc_payment_debt.format_show) }}
                                    </div>
                                    <div v-else class="font-weight-bold px-4 doc_payment_debt_value">
                                        {{ documentData.dati_pagamento_fields.doc_payment_debt.value }}
                                    </div>
                                </v-col>

                            </v-row>
                        </v-col>

                        <v-col cols="4">
                                <img :onerror="hideParent()" :src="getImg('/images/bollettainterattiva/Template5/Core/img-costi.svg')" />
                        </v-col>
                        </v-row>
                        </v-container>
                    </v-card-title>
                    <v-row class="SecondContentBollettaFornituraCard5Dt"  >
                    <v-col sm="4">
                    </v-col>
                    <v-col sm="4" style="text-align: center;">
                        <v-btn
                            icon
                            @click="openClose"
                        >
                            <span class="costi_show_label" >{{show ?   $t('message.bolletta_interattiva_template5_costi_chiudi') :  $t('message.bolletta_interattiva_template5_costi_espandi')}}</span>

                            <v-icon color="#195aa2">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col sm="4">
                    </v-col>
                </v-row>
                    <v-expand-transition>
                    <div v-show="show" id="bolletta_interattiva_storico_consumi_header">
                        <div v-for="(voice, i) in documentData.fatt_interattiva_data.sintesi_importi.grafico_importi" :key="'costi_voice-' + i"  :class="'costi_voice costi_voice-' + i" > 
                        <v-row> 
                            <v-col cols="4">
                                <p class="voice_costi_label">{{translateLabel('message.bolletta_interattiva_template5_costi_voices.' + voice.label,voice.label)}}</p>
                            </v-col> 
                            <v-col cols="4">
                                <v-tooltip v-if="voice.tooltip" color="#195aa2" top>
                                    <template v-slot:activator="{ on }">
                                        <img :onerror="hide()" v-on="on" width="10%" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-search.svg')" />
                                    </template>
                                    <span class="white--text" >{{translateLabel('message.bolletta_interattiva_template5_costi_voices_tooltip.' + voice.tooltip,voice.tooltip)}}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="4" v-if="voice.importo" >
                                <span class="voice_costi_value">{{voice.importo}}</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="voice.value">
                            <div class="costi-barra-container">
                                <div class="costi-barra-bg">
                                </div> 
                                <div v-if="(voice.value && voice.value!=0)" :style="{width: getWidthBar(voice.value) , backgroundColor: (voice.color!=null) ? voice.color : '#69a9dc'}" :class="(voice.value>0) ? 'costi-barra-positive' : 'costi-barra-negative'">
                                </div>
                            </div>
                        </v-row>
                        </div>
                    </div>
                    </v-expand-transition>
                    <!-- Paga la tua bolletta -->
                    <div  v-if="documentData.fatt_interattiva_data.riepilogo_doc.paga_ora == '1' && documentData.document_payment_expiry" id="rowBollettaPagamentoCampana" class="d-md-flex mb-10 justify-center">
              
                            <div class="">
                                <span>{{translateLabel('message.bolletta_interattiva_template5_costi_first')}}</span>
                        
                                <b style="margin-left: 10px;" >{{documentData.dati_pagamento_fields.doc_payment_expiry.value | formatDate(documentData.dati_pagamento_fields.doc_payment_expiry.format_show, $t("message.contract_date_not_available"))}}</b>
                            </div>
                            <div class="d-flex flex-row justify-center align-center">
                                <img :src="getImg('/images/bollettainterattiva/Template5/Core/ico-campana.svg')" id="ico-campana"/>
                                <div id="scarica_width">
                                    <span class="show_dialog_info" @click="scaricaPromemoria()" style="cursor: pointer; font-weight: bold;" >{{translateLabel('message.bolletta_interattiva_template5_costi_second')}}</span>
                                </div>
                            </div>
                 
                    </div>
                    <v-row v-if="documentData.fatt_interattiva_data.riepilogo_doc.paga_ora == '1'" class="d-flex justify-center align-center" id="rowBollettaCosti">
                        <v-col cols="2">
                            <img :src="getImg('/images/bollettainterattiva/Template5/Core/ico-soldi.svg')" id="ico-soldi"/>   
                        </v-col>
                        <v-col cols="10">
                            <p style="text-align: left; " id="SpanBollettaCostiSoldi">{{translateLabel('message.bolletta_interattiva_template5_costi_third')}}</p>
                        </v-col>
                    </v-row>                     
                    <v-row v-if="documentData.fatt_interattiva_data.riepilogo_doc.paga_ora == '1'" class="d-md-flex justify-center align-center mb-5">
                        <v-btn v-if="documentData.fatt_interattiva_data.riepilogo_doc.paga_ora == '1'"
                            class="bollettaInterattiva5_webButton white--text v-btn-shadow"
                            id="ButtonBollettaCostiSoldi_L"
                            style="margin: 5px"
                                @click="pagaOra()"
                                x-large
                                color="black"
                                >
                                <span class="bollettaInterattiva5_webButton_content"
                                v-html="$t('message.bolletta_interattiva_template5_costi_button_first')"></span> 
                        </v-btn>
                        <v-btn
                            class="bollettaInterattiva5_webButton white--text v-btn-shadow"
                            id="ButtonBollettaCostiSoldi_R"
                            style="margin: 5px"
                                @click="bollettaPDF()"
                                x-large
                                color="black"
                                >
                                <span class="bollettaInterattiva5_webButton_content"
                                v-html="$t('message.bolletta_interattiva_template5_costi_button_second')"></span> 
                        </v-btn>
                    </v-row>
                    
                    <div v-if="documentData.fatt_interattiva_data.riepilogo_doc.paga_ora == '1'" class="scopriModalitaPagamento"  align="center" justify="center">
                        <span class="show_dialog_info" 
                        @click="paymentMethod()">{{translateLabel('message.bolletta_interattiva_template5_info_pagamento_button_label')}}</span>
                    </div>
            </v-container>
            </v-card>
        </v-row>
        <!--  -->
        <v-dialog
            v-model="showWorldlineErrorDialog"
            width="500px"
            >
            <v-card align="center" justify="center">
                <v-card-text class="pa-5">
                    <p>{{$t('message.bolletta_interattiva_pagaora_worldline_error')}}</p>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="center" align="center">
                    <v-btn
                        class="bollettaInterattiva5_webButton white--text v-btn-shadow btn-worldline"
                        id="ButtonBollettaPagaOra"
                            @click="reloadOnUnoenergyPagamentoError()"
                            x-large
                            color="black"
                            >
                            <span class="bollettaInterattiva5_webButton_content">CHIUDI</span> 
                    </v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--  -->
  </v-container>
</template>

<script>
//import { services } from '../scripts/services/serviceBuilder'
import utility from '../Core/utility';
import { createEvent} from 'ics';

import updateToken from '../../../middleware/update-token';
import Vue from "vue";
import AppSettingsService from '../../../scripts/services/appSettingsService';
import { services } from '../../../scripts/services/serviceBuilder';

export default {
    mixins: [utility],
    name: 'BollettaInterattivaFornituraCosti5Dt',
    data: function() {
    return {
        showDialog: false,
        show: false,
        bolletta_interattiva_dett_forn_header:false,
        showWorldlineErrorDialog: false,
    }},
    props: {
        item: {},
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
            type: Function,
        },
        getImg: {
            type: Function,
        },
    },
    methods: {
        openClose() {
            let that = this;
            //that.$emit("openClose", that.$vnode.key);
            that.show = !that.show;
        }, 
        getWidthBar(value){
            let perc = (Math.abs(parseInt(value))/Math.abs(parseInt(this.documentData.dati_pagamento_fields.doc_amount.value)) * 100);
            if(perc > 100)
                perc = 100;
            else
                if(perc<0) 
                    perc = 0;
            return perc + '%';
        },
        scaricaPromemoria: async function(){
            //Generate ICS file
            let data_scadenza = new Date(this.documentData.dati_pagamento_fields.doc_payment_expiry.value); 
            console.log(data_scadenza);
            let alarm = [{ action: 'display', description: 'Reminder', trigger: { hours: 24, minutes: 0, before: true } }];
            const event = {
                productId:"UnoEnergy",
                start: [data_scadenza.getFullYear(), data_scadenza.getMonth()+1, data_scadenza.getDate(), 11, 0],
                title: "Pagamento bolletta",
                description: "Effettua il pagamento della bolletta",
                status: 'CONFIRMED',
                //recurrenceRule: 'FREQ=MONTHLY;BYMONTHDAY=-1;UNTIL='+data_scadenza.getFullYear()+(data_scadenza.getMonth()+1).toString().padStart(2, '0')+data_scadenza.getDate()+'T000000',
                alarms: alarm
            }
        

            const filename = 'Scadenza Bolletta.ics'
            const file = await new Promise((resolve, reject) => {
                createEvent(event, (error, value) => {
                if (error) {
                    reject(error)
                }
                
                resolve(new File([value], filename, { type: 'plain/text' }))
                })
            })
            const url = URL.createObjectURL(file);
            
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;
            
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            
            URL.revokeObjectURL(url);

        },
        pagaOra: function(){
            let that = this
            let requests = [];

            updateToken(Vue.prototype.$isAuthenticated.value);

            let pagaoraObj = {
                    success_url: window.location.origin + '/worldlineEsito?id=' + that.documentData.crypto + '&annullo=0',
                    error_url: window.location.origin + '/worldlineAnnullo?id=' + that.documentData.crypto + '&annullo=1',
                    callback_url: AppSettingsService.getSettings().worldlineS2sUrlpost + '/' + that.documentData.crypto + '/' + btoa(window.location.hostname)
            }
            
            requests.push(services.apiCallerBollettaInterattivaUnoenergy.postUnoenergyPagamento(that.documentData.crypto, pagaoraObj));
            
            Promise.all(requests).then((responses) => {                
                if(responses === null || responses[0].data == "ko"){
                    //Error
                    that.showWorldlineErrorDialog = true;
                    //console.log("KO");  ///////////////
                }else{
                    //Ok 
                    
                    //console.log(responses[0].data); ////////////////////////
                    window.location.href = responses[0].data;
                }
            }).catch(() => {
                that.showWorldlineErrorDialog = true;
                //console.log("ko catch");   ////////////////////
            });
        },
        reloadOnUnoenergyPagamentoError: function(){
            this.showWorldlineErrorDialog = false;
            //location.reload();
        },
        paymentMethod: function(){
            window.open('https://www.unoenergy.it/info-sul-pagamento/ ')
        },
        bollettaPDF: function(){
            var that = this;

            services.apiCallerMedia.download(that.documentData.detail)
                .then(response =>  {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.pdf');
                    document.body.appendChild(link);
                    link.click();
                })               
                .catch(error => {
                    console.log(error);
                });
            
        }
    },
    created: function () {
        let that = this;
		that.bolletta_interattiva_dett_forn_header = Boolean(Number(that.documentData.bolletta_interattiva_dett_forn_header));
	}
}
</script>
