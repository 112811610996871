<template>
    <v-container>
        <div>
            <v-row justify="center" align="center">
            <p class="text-h6"><b>
                Worldline
            </b></p>
            </v-row>
            <v-row justify="center" align="center">
            <p>
                {{$t('message.bolletta_interattiva_pagaora_worldline_esito_annullo')}}
            </p>
            </v-row>
            <v-row justify="center" align="center">
            <v-btn @click="goBackToBolletta()"
                  class="bollettaInterattiva5_webButton white--text v-btn-shadow"
                  id="ButtonAnnulloWorldline"
                  x-large
                  color="black"
                  >
                  <span class="bollettaInterattiva5_webButton_content">TORNA ALLA BOLLETTA</span>
            </v-btn>
            </v-row>
        </div>
    </v-container>
</template>
<script>

export default {
    data: () => ({
        
    }),
    methods:{
        goBackToBolletta:function(){
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('id');
            window.open("/bollettainterattiva" + "?id="+myParam, '_self');
        },
    },
    computed: {
       
    }
}
</script>
    
    