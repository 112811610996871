import ApiCallerHelper from "./apiCallerHelper";
import ApiCallerMenu from "./apiCallerMenu";
import ApiCallerDocument from "./apiCallerDocument";
import ApiCallerContract from "./apiCallerContract";
import ApiCallerMedia from "./apiCallerMedia";
import ApiCallerFaq from "./apiCallerFaq";
import ApiCallerRealm from "./apiCallerRealm";
import ApiCallerFooter from "./apiCallerFooter";
import ApiCallerContact from "./apiCallerContact";
import ApiCallerSocial from "./apiCallerSocial";
import ApiCallerCustomer from "./apiCallerCustomer";
import ApiCallerInvoice from "./apiCallerInvoice";
import ApiCallerMessage from "./apiCallerMessage";
import ApiCallerRegistration from "./apiCallerRegistration";
import ApiCallerLocale from "./apiCallerLocale";
import ApiCallerPublicAccess from "./apiCallerPublicAccess";
import ApiCallerBollettaInterattiva from "./apiCallerBollettaInterattiva";
import ApiCallerBollettaInterattivaDuferco from "./apiCallerBollettaInterattivaDuferco";
import ApiCallerBollettaInterattivaUnoenergy from "./apiCallerBollettaInterattivaUnoenergy";

class Services {

    get apiCallerHelper() {
        return new ApiCallerHelper();
    }

    get apiCallerMenu() {
        return new ApiCallerMenu(this.apiCallerHelper);
    }
    
    get apiCallerDocument() {
        return new ApiCallerDocument(this.apiCallerHelper);
    }

    get apiCallerContract() {
        return new ApiCallerContract(this.apiCallerHelper);
    }

    get apiCallerMedia() {
        return new ApiCallerMedia(this.apiCallerHelper);
    }

    get apiCallerFaq() {
        return new ApiCallerFaq(this.apiCallerHelper);
    }

    get apiCallerRealm() {
        return new ApiCallerRealm(this.apiCallerHelper);
    }

    get apiCallerFooter() {
        return new ApiCallerFooter(this.apiCallerHelper);
    }

    get apiCallerContact() {
        return new ApiCallerContact(this.apiCallerHelper);
    }

    get apiCallerSocial() {
        return new ApiCallerSocial(this.apiCallerHelper);
    }

    get apiCallerCustomer() {
        return new ApiCallerCustomer(this.apiCallerHelper);
    }

    get apiCallerInvoice() {
        return new ApiCallerInvoice(this.apiCallerHelper);
    }

    get apiCallerMessage() {
        return new ApiCallerMessage(this.apiCallerHelper);
    }

    get apiCallerRegistration() {
        return new ApiCallerRegistration(this.apiCallerHelper);
    }

    get apiCallerLocale() {
        return new ApiCallerLocale(this.apiCallerHelper);
    }

    get apiCallerPublicAccess() {
        return new ApiCallerPublicAccess(this.apiCallerHelper);
    }

    get apiCallerBollettaInterattiva() {
        return new ApiCallerBollettaInterattiva(this.apiCallerHelper);
    }

    get apiCallerBollettaInterattivaDuferco() {
        return new ApiCallerBollettaInterattivaDuferco(this.apiCallerHelper);
    }

    get apiCallerBollettaInterattivaUnoenergy() {
        return new ApiCallerBollettaInterattivaUnoenergy(this.apiCallerHelper);
    }
}

export var services = new Services();
