<template>
  <v-container id="BollettaInterattivaInfoPagamento5Dt" class="pa-7">
    <v-row class="d-flex" justify="center" align="center">
      <v-card class="card_home_container">
        <v-row class="FirstContentBollettaInterattivaInfoPagamento5Dt-flex" justify="center" align="center" id="pagamento_lista">
          <v-col cols="12" justify="center" align="center">
            <span v-if="this.documentData.fatt_interattiva_data.insoluti == null || (Array.isArray(this.documentData.fatt_interattiva_data.insoluti) && this.documentData.fatt_interattiva_data.insoluti.length === 0)" class="pagamento_status">{{translateLabel('message.bolletta_interattiva_template5_pagamento_status')}}</span>        
            <span v-else class="pagamento_status">{{translateLabel('message.bolletta_interattiva_template5_pagamento_insoluti_third')}} </span><br/><span v-for="(item, key)  in this.documentData.fatt_interattiva_data.insoluti" :key="key"   class="pagamento_status_list_mobile"> N. {{ item.numero_documento }} scaduta il {{ item.data_scadenza }} con importo {{ item.importo_originale }}<br></span>             
            <br/>
          </v-col>
        </v-row>    
        
        <div  class="scopriModalitaPagamento"  align="center" justify="center">
            <span class="show_dialog_info" 
            @click="paymentMethod()">{{translateLabel('message.bolletta_interattiva_template5_info_pagamento_button_label')}}</span>
        </div>
        
        <div v-if="this.documentData.fatt_interattiva_data.riepilogo_doc.domiciliazione_bancaria == '1'">
          <v-row id="divBolletaPagamentoAttivaOra" justify="center" align="center" >
            <v-col cols="2" style="padding: 0">
              <img :src="getImg('/images/bollettainterattiva/Template5/Core/ico-domiciliazione.svg')"  id="ico-domiciliazione"/>
            </v-col>
            <v-col cols="10">  
              <span id="span_invoices_n_contract_label" class="invoices_n_contract_label">{{translateLabel('message.content_modalita_pagamento_text_first_5Dt')}}  <b id="span_invoices_n_contract_label">{{translateLabel('message.content_modalita_pagamento_text_second_5Dt')}}</b></span>
            </v-col>
          </v-row>                     
          <v-row class="SecondContentBollettaInterattivaWebTile5Dt d-flex" justify="center" align="center" >
            <v-btn
                class="bollettaInterattiva5_webButton white--text v-btn-shadow"
                  @click="attivaOra()"
                  x-large
                  color="black"
                  >
                  <span class="bollettaInterattiva5_webButton_content"
                  v-html="$t('message.content_modalita_pagamento_text_button_5Dt')"></span> 
            </v-btn>
          </v-row>
        </div>
      </v-card>
    </v-row> 
  </v-container>
</template>

<script>
import { services } from '../../../scripts/services/serviceBuilder'

export default {
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
    downloadFile: function() {
      var that = this;

      services.apiCallerMedia.download(that.documentData.detail)
        .then(response =>  {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
        })               
        .catch(error => {
            console.log(error);
        });
    },
    paymentMethod: function(){
      window.open('https://www.unoenergy.it/info-sul-pagamento')
    },
    attivaOra: function(){
      window.open('https://variazioni-contrattuali.unoenergy.it/domiciliazione-bancaria')
    }
  }

}
</script>

<style>

</style>