<template>
    <v-container id="bollettaInterattivaDatiPagamento2">
        <v-row no-gutters>
            <v-col sm="12">
                <v-card>
                    <v-card-title class="pb-0">
                        <div :class="documentData.bolletta_interattiva_esponi_2colonne_pagamento==1 ?'row contentCart':'monocol contentCart'">
                        <!--v-row-->
                            <div :class="documentData.bolletta_interattiva_esponi_2colonne_pagamento==1 ?'col col-6 sectionParteSuperiore':'monocol sectionParteSuperiore'">
                            <!--v-col cols="6"-->

                                <v-col cols="12" v-if="documentData.document_payment_debt !== 0">
                                    <div class="font-weight-light px-4">{{$t("message." + documentData.dati_pagamento_fields.doc_amount.label)}}</div>
                                    <div v-if="documentData.dati_pagamento_fields.doc_amount.format == 'amount'" class="pb-1 font-weight-light px-4">
                                      {{ documentData.dati_pagamento_fields.doc_amount.value | formatAmount(documentData.dati_pagamento_fields.doc_amount.format_show, documentData.currency_sigle) }}
                                    </div>
                                    <div v-else-if="documentData.dati_pagamento_fields.doc_amount.format == 'number'" class="pb-1 font-weight-light px-4">
                                      {{ documentData.dati_pagamento_fields.doc_amount.value | formatNumber(documentData.dati_pagamento_fields.doc_amount.format_show) }}
                                    </div>
                                    <div v-else class="pb-1 font-weight-light px-4">
                                      {{ documentData.dati_pagamento_fields.doc_amount.value }}
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <div v-if="documentData.dati_pagamento_fields.doc_payment_debt.value < 0.00">
                                        <div class="font-weight-bold px-4">{{$t("message.doc_payment_debt_negative_label")}}</div> 
                                    </div> 
                                    <div v-else>   
                                        <div class="font-weight-bold px-4">{{$t("message." + documentData.dati_pagamento_fields.doc_payment_debt.label)}}</div>  
                                    </div>              
<!--                                    <div class="font-weight-bold px-4">{{ documentData.document_payment_debt + ' euro' }}</div>-->
                                    <div v-if="documentData.dati_pagamento_fields.doc_payment_debt.format == 'amount'" class="font-weight-bold px-4">
                                    {{ documentData.dati_pagamento_fields.doc_payment_debt.value | formatAmount(documentData.dati_pagamento_fields.doc_payment_debt.format_show, documentData.currency_sigle, "1") }}
                                    </div>
                                    <div v-else-if="documentData.dati_pagamento_fields.doc_payment_debt.format == 'number'" class="font-weight-bold px-4">
                                    {{ documentData.dati_pagamento_fields.doc_payment_debt.value | formatNumber(documentData.dati_pagamento_fields.doc_payment_debt.format_show, "1") }}
                                    </div>
                                    <div v-else class="font-weight-bold px-4">
                                    {{ Math.abs(documentData.dati_pagamento_fields.doc_payment_debt.value) }}
                                    </div>            
                                </v-col>
                            <!--/v-col-->
                            </div>

                            <div :class="documentData.bolletta_interattiva_esponi_2colonne_pagamento==1 ?'col col-6 sectionParteInferiore':'monocol sectionParteInferiore'">

                            <!--v-col cols="6"-->
                               
                                <!--   Section ok pagamento  -->

                                <div v-if="documentData.bolletta_interattiva_esponi_stato_pagamento==1 && documentData.dati_pagamento_fields.doc_payment_debt.value > 0.00 &&
                                    (bolletta_interattiva_document_payment_status==null || bolletta_interattiva_document_payment_status==0 || bolletta_interattiva_document_payment_status==3 || bolletta_interattiva_document_payment_status==4)" class="datacontainer">
                                    
                                    <div v-if="documentData.document_payment != null && documentData.document_payment.toLowerCase() == 'rid'">
                                        <div class="notifiedUpperMessagePaidContainer">                              
                                        <div>
                                            <span class="notification" v-html="$t('message.bolletta_interattiva_dati_pagamento_pagamento_rid')"></span>
                                        </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="bolletta_interattiva_document_payment_status==0">
                                            <div class="notifiedUpperMessageCanceledContainer">
                                            <v-icon class="notifiedImageCanceledContainer">mdi-emoticon-sad-outline</v-icon>
                                            <div>
                                                <span class="notification" v-html="$t('message.bolletta_interattiva_dati_pagamento_pagamento_annullato')"></span>
                                            </div>
                                            </div>
                                        </div>
                                        <div v-if="bolletta_interattiva_document_payment_status==3">
                                            <div class="notifiedUpperMessageValidateContainer">
                                            <v-icon class="notifiedImageValidateContainer">mdi-timer-sand</v-icon>
                                            <div>
                                                <span class="notification" v-html="$t('message.bolletta_interattiva_dati_pagamento_pagamento_davalidare')"></span>
                                            </div>
                                            </div>
                                        </div>
                                        <div v-if="bolletta_interattiva_document_payment_status==4">
                                            <div class="notifiedUpperMessagePaidContainer">
                                            <img :src="getPaymentStatusIco()" id="payment_status_ico" style="height: 1.25em; width: 1.25em;" />
                                            <div>
                                                <span class="notification" v-html="$t('message.bolletta_interattiva_dati_pagamento_pagamento_ok')"></span>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                <!-- Section Paga Ora--> 
                                <v-row v-if="(bolletta_interattiva_document_payment_status==1 || bolletta_interattiva_document_payment_status==2)
                                 && (documentData.document_payment == null || documentData.document_payment.toLowerCase() != 'rid') && documentData.bolletta_interattiva_esponi_paga_ora==1" justify="center">
                                    <v-row v-if="bolletta_interattiva_pagaora_nexi_attivazione" justify="center">
                                        <v-col cols="12" align="center">
                                            <div class="text-subtitle-1">{{$t('message.bolletta_interattiva_pagaora_nexi')}}</div>
                                            <img style="max-width: 15%;" :src="getNexiLogo()" id="datiPagamentoNexiLogo" class="pr-0"/>
                                            <v-btn
                                                style="border-radius: 8px !important; max-width: 50%;"
                                                class="black--text"
                                                id="buttonPagaOraNexi"
                                                @click="dufercoPagamento()">
                                                {{$t('message.button_pagaora_nexi')}}
                                            </v-btn>
                                        
                                            <!--div v-if="documentData.document_payment_debt > 0" class="documentPaymentExpiryText text-subtitle-2 pt-2 font-weight-light"> {{ 'Scadenza ' + documentData.document_payment_expiry }}</div-->
                                        </v-col>
                                        <v-col cols="12">
                                            <img/>
                                        </v-col>
                                    </v-row>
                                    <v-row id="rowPagopa">
                                        <v-col cols="12" align="center" v-if="bolletta_interattiva_pagaora_pagopa_attivazione">
                                            <div class="text-subtitle-1">{{$t('message.bolletta_interattiva_pagaora_pagopa')}}</div>
                                            <img style="max-width: 15%;" :src="getPagopaLogo()" id="datiPagamentoPagopaLogo" class="pl-1"/>
                                            <v-btn
                                                style="border-radius: 8px !important; max-width: 60%;"
                                                class="black--text"
                                                id="buttonPagaOraPagopa"
                                                @click="pagopaPagamento()">
                                                {{$t('message.button_pagaora_pagopa')}}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row id="rowBollettino">
                                        <v-col cols="12" align="center" v-if="bolletta_interattiva_pagaora_boll">
                                            <div class="text-subtitle-1">{{$t('message.bolletta_interattiva_pagaora_boll')}}</div>
                                            <img style="max-width: 15%;" :src="getBollettinoLogo()" id="datiPagamentoBollettinoLogo" class="pl-1"/>
                                            <v-btn
                                                style="border-radius: 8px !important; max-width: 60%;"
                                                class="black--text"
                                                id="buttonBollettino"
                                                @click="downloadBollettino()">
                                                {{$t('message.bolletta_interattiva_button_pagaora_boll')}}
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                
                                </v-row>

                                <!--   Rid  -->
                                <v-row v-if="(documentData.document_payment == null || documentData.document_payment.toLowerCase() != 'rid') && documentData.bolletta_interattiva_esponi_rid==1 ">
                                    <div class="datacontainer">
                                    <v-img class="ridImage" :src="getRidImage()" contain />
                                    </div>
                                    <div class="datacontainer">
                                    <span class="ridtitle" v-html="$t('message.bolletta_interattiva_rid_label')"></span>
                                    </div>
                                    <div class="datacontainer">
                                    <span class="ridtext" v-html="$t('message.bolletta_interattiva_rid_text')"></span>
                                    </div>
                                    <div class="datacontainer">
                                    <v-btn v-bind="size" :href=documentData.bolletta_interattiva_rid_link target="_blank" id="buttonRid" class="ridButton" depressed
                                        outlined rounded> <span class="ridButton_content" v-html="$t('message.bolletta_interattiva_rid_button')"></span>
                                    </v-btn>
                                    </div>
                                </v-row>

                                <v-col v-if="documentData.document_payment_debt > 0.00"
                                     class="datacontainer">
                                    <div v-if="documentData.document_payment_expiry"  class="expirydateContainer" >
                                        {{$t("message.bolletta_interattiva_dati_pagamento_expiry_date")}} : {{documentData.dati_pagamento_fields.doc_payment_expiry.value | formatDate(documentData.dati_pagamento_fields.doc_payment_expiry.format_show, $t("message.contract_date_not_available"))}}
                                    </div>
                                </v-col>

                                <!--v-row v-if="documentData.bolletta_interattiva_esponi_stato_pagamento==1" class="datacontainer">
                                        <div class="notifiedUpperMessagePaidContainer">
                                            <v-icon class="notifiedImagePaidContainer">mdi-check-circle-outline</v-icon>
                                            <span class="pagamentoStatus" v-html="$t('message.bolletta_interattiva_dati_pagamento_status')"></span>
                                        </div>
                                </v-row-->

                                

                            <!--/v-col-->
                            </div>
                        <!--/v-col-->
                        </div>
                    </v-card-title>
                    <v-expand-transition>
                        <div v-show="show">
                            <v-card-text>
                            <p class="text-h5">{{$t('message.bolletta_interattiva_sintesi_importi_header')}}</p>
                            <v-simple-table id="sintesiImportiTabella1">
                                <template v-slot:default>
                                <tbody>
                                    <tr v-for="(item,index) in importiValues" :key="index">
                                        <td v-if="item.key==='TOTALE DA PAGARE' || item.key==='TOTALE A CREDITO'" class="text-right" style="border-right: 3px solid white; width: 75%;">{{ item.key }}</td>
                                        <td v-else class="text-left" style="border-right: 3px solid white; width: 75%;">{{ item.key }}</td>
                                        <td class="text-right">{{ getImportoValue(item.value) }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                            <div v-if="showRiepilogoIvaTable()">
                                <p class="text-h7 pt-6">{{$t('message.bolletta_interattiva_sintesi_importi_header_iva')}}</p>
                                <v-simple-table id="sintesiImportiTabella2">
                                    <template v-slot:default>                               
                                    <tbody>
                                        <tr>
                                            <td class="text-left" style="border-right: 3px solid white; width: 30%;">Aliquota IVA</td>
                                            <td class="text-left" style="border-right: 3px solid white; width: 45%;">Imponibile</td>
                                            <td class="text-left" style="border-right: 3px solid white; width: 25%;">Importo</td>
                                        </tr>
                                        <tr v-for="item in aliquoteItems" :key="item.aliquota_iva">
                                        <td class="text-left" style="border-right: 3px solid white; width: 30%;">{{ item.aliquota_iva + '%' }}</td>
                                        <td class="text-left" style="border-right: 3px solid white; width: 45%;">{{ item.imponibile + ' euro' }}</td>
                                        <td class="text-left" style="border-right: 3px solid white; width: 25%;">{{ item.importo + ' euro' }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                            </v-card-text>
                            <v-row v-show="showGraficoImporti()" class="pa-3" style="position:relative; height: 50%; width: 100%; min-height: 300px; min-width: 300px;">
                                <canvas id="grafico_importi"> </canvas>
                            </v-row>
                        </div>
                    </v-expand-transition>
                    <div class="arrow-label" > {{show ? $t('message.close') : $t('message.detail') }}</div>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters >
            <v-col cols="12" align="center">
                <v-btn
                    icon
                    @click="show = !show"
                >
                    <v-icon color="white" >{{ show ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <!-- Nexi Duferco Pagmento error -->
        <v-dialog
            v-model="showNexiErrorDialog"
            width="500px"
            >
            <v-card>
                <v-card-text class="text-subtitle-1 pa-5">
                    {{$t('message.bolletta_interattiva_pagaora_nexi_error')}}
                </v-card-text>
                <v-card-actions>
                    <v-btn block @click="reloadOnDufercoPagamentoError()">Chiudi</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import AppSettingsService from '../scripts/services/appSettingsService';
import { services } from '../scripts/services/serviceBuilder'
import Chart from "chart.js";
import updateToken from '../middleware/update-token';
import Vue from "vue";

export default {
    name: 'bollettaInterattivaDatiPagamento2',
    data: function() {
        let that = this;
        return {
            show: false,
            windowWidth: window.innerWidth,           
            aliquoteItems:[],         
            graficoImportiValue: [],
            importiValues: [],
            chartData: {
                type: 'pie',
                data:{
                    labels: [],
                    datasets:[{                   
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4
                    }],
                },
                options: {
                    legend: {
                        display: true,
                        position: that.$vuetify.breakpoint.width <= 900? 'bottom' :'right',
                        align: that.$vuetify.breakpoint.width <= 900? 'start' :'center',
                        labels: {
                            usePointStyle: true,
                            pointStyle: "circle",
                            padding: 25,
                            fontSize:14
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation:false,
                },
            },
            chartVariable: null,
            bolletta_interattiva_sintesi_importi_riepilogo_iva: false,
            bolletta_interattiva_sintesi_import_grafico: false,
            bolletta_interattiva_bottone_apri_sintesi_importi: false,
            bolletta_interattiva_pagaora_nexi_attivazione: false,
            bolletta_interattiva_pagaora_pagopa_attivazione: false,
            bolletta_interattiva_pagaora_boll: false,

            showNexiErrorDialog: false,

            bolletta_interattiva_document_payment_status: -1,
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        }
    },
    computed: {

    },
    components: {
        
    },
    methods: {
        onResize() {
            /*this.windowWidth = window.innerWidth;
            if(this.windowWidth<850)
                this.chartData.options.legend.position = 'bottom';
                this.chartData.options.legend.labels.fontSize = 10;
                this.chartData.options.legend.align = 'start'*/
        },
        getRidImage: function() {
                return './' + location.hostname + '/images/bollettainterattiva/rid.jpg';
        },
        getNexiLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/nexi_logo.png";
        },
        getBollettinoLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/bollettino_icon.jpg";
        },
        getPagopaLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/pagopa_logo.png";
        },
        getPaymentStatusIco: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/check_mark.svg";
        },
        loadImportiValues: function(){
            let that = this;
            let startingValue = that.documentData.fatt_interattiva_data.sintesi_importi.tabella_importi;
            for (let prop in startingValue) {
                that.importiValues.push({
                    key: prop,
                    value: startingValue[prop]
                });
            }
        },
        getImportoValue: function(value){
            if(value.includes('euro'))
                return value;
            return value + ' euro';
        },
        createChart: function() {
            let that = this;
            let ctx = document.getElementById("grafico_importi").getContext('2d');
            //Preparing data for chart
            let colors = that.graficoImportiValue.map(x=>x.color);
            let dataValues = that.graficoImportiValue.map(x=>x.value);
            let labels = that.graficoImportiValue.map(x=>x.label);
            that.chartData.data.labels = labels;
            that.chartData.data.datasets[0].data = dataValues;
            that.chartData.data.datasets[0].backgroundColor = colors;
            that.chartVariable = new Chart(ctx, that.chartData);
            //that.chartVariable.render();
            //that.chartVariable.destroy();
    
        },
        showRiepilogoIvaTable: function(){
            let that = this;
            if(that.bolletta_interattiva_sintesi_importi_riepilogo_iva && that.aliquoteItems.length > 0)
                return true;
            else
                return false;
        },
        showGraficoImporti: function(){
            let that = this;
            if(that.bolletta_interattiva_sintesi_import_grafico && that.graficoImportiValue.length > 0)
                return true;
            else
                return false;
        },
        pagopaPagamento: function(){
            let that = this;
            const url = that.documentData.bolletta_interattiva_pagaora_pagopa_link + '?act=portal&id=' + that.documentData.crypto;
            window.open(url, '_blank');
        },
        dufercoPagamento: function(){
            updateToken(Vue.prototype.$isAuthenticated.value);

            let that = this;
            let requests = [];
            //Calling api_duferco_verifica before proceeding on api_duferco_pagamento
            requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoVerifica(that.documentData.crypto));
            Promise.all(requests).then((responses) => {
                let verificaValue = responses[0].data;
                console.log("Verifica ha valore:", verificaValue);
                if(verificaValue === 1){
                    //OK
                    //Calling profiloDuferco
                    requests = [];
                    requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoProfilo(that.documentData.crypto));
                    Promise.all(requests).then((responses) => {
                        let profiloDuferco = responses[0].data;
                        //Calling api_duferco_pagamento
                        requests = [];
                        requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoPagamento(that.documentData.crypto));
                        Promise.all(requests).then((responses) => {
                            let postData = responses[0].data.post_data;
                            let nexiUrl = responses[0].data.url;
                            //Preparing url = /nexiEsito?id={crypto}&annullo=0 
                            // url_back = /nexiAnnullo?id={crypto}&annullo=1
                            postData.url = window.location.origin + '/nexiEsito?id=' + that.documentData.crypto + '&annullo=0'
                            postData.url_back = window.location.origin + '/nexiAnnullo?id=' + that.documentData.crypto + '&annullo=1'
                            postData.urlpost = AppSettingsService.getSettings().nexiS2sUrlpost + '/' + that.documentData.crypto + '/' + btoa(window.location.hostname)
                            if(postData === null || nexiUrl === null){
                                //Error
                                that.showNexiErrorDialog = true;
                            }else{
                                //Ok go for payment on Nexi
                                //TODO: use postData and nexiUrl to call NEXI services
                                if(profiloDuferco &&  profiloDuferco.emailFatturazione)
                                    postData['mail'] = profiloDuferco.emailFatturazione;
                                
                                postData['descrizione'] = '_*' + that.documentData.document_number + '__';
                                
                                that.postForm (nexiUrl,postData, 'post');
                                /*services.apiCallerBollettaInterattivaDuferco.postNexiPagamento(nexiUrl,postData).then((response)=>{
                                    console.log(response);
                                });*/
                            }
                        });
                    });
                }else{
                   // Error
                   that.showNexiErrorDialog = true;
                }
            });
            
        },
        postForm(path, params, method) {
            method = method || 'post';

            var form = document.createElement('form');
            form.setAttribute('method', method);
            form.setAttribute('action', path);

            for (var key in params) {
                var hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);

                form.appendChild(hiddenField);
            }

            document.body.appendChild(form);
            form.submit();
        },
        dufercoVerifica: function(){
            let that = this;
            let requests = [];
            //Calling api_duferco_verifica before proceeding on api_duferco_pagamento
            requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoVerifica(that.documentData.crypto));
            return Promise.all(requests);
        },
        reloadOnDufercoPagamentoError: function(){
            let that = this;
            that.showNexiErrorDialog = false;
            location.reload();
        },
        downloadBollettino: function() {
            let that = this;

            services.apiCallerMedia.download(that.documentData.detail)
                .then(response =>  {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.pdf');
                    document.body.appendChild(link);
                    link.click();
                })               
                .catch(error => {
                    console.log(error);
                });
        },
            
    },
	created: async function () {
        let that = this;
        that.aliquoteItems = that.documentData.fatt_interattiva_data.sintesi_importi.tabella_iva;
        that.graficoImportiValue = that.documentData.fatt_interattiva_data.sintesi_importi.grafico_importi;
        that.loadImportiValues();

        that.bolletta_interattiva_sintesi_importi_riepilogo_iva = Boolean(Number(that.documentData.bolletta_interattiva_sintesi_importi_riepilogo_iva));
        that.bolletta_interattiva_sintesi_import_grafico = Boolean(Number(that.documentData.bolletta_interattiva_sintesi_import_grafico));
        that.bolletta_interattiva_bottone_apri_sintesi_importi =  Boolean(Number(that.documentData.bolletta_interattiva_bottone_apri_sintesi_importi));
        
        if(that.bolletta_interattiva_bottone_apri_sintesi_importi){
            that.show = true;
        }
        
        that.bolletta_interattiva_pagaora_pagopa_attivazione = Boolean(Number(that.documentData.bolletta_interattiva_pagaora_pagopa_attivazione));
        that.bolletta_interattiva_pagaora_nexi_attivazione = Boolean(Number(that.documentData.bolletta_interattiva_pagaora_nexi_attivazione));

        that.bolletta_interattiva_pagaora_boll = Boolean(Number(that.documentData.bolletta_interattiva_pagaora_boll));

        if(!that.documentData.bolletta_interattiva_esponi_stato_pagamento_data || that.documentData.bolletta_interattiva_esponi_stato_pagamento_data.toUpperCase() == 'DB')
            that.bolletta_interattiva_document_payment_status = that.documentData.document_payment_status;
        else
          {
            that.dufercoVerifica().then((responses) => {
            that.bolletta_interattiva_document_payment_status  = responses[0].data;
            });
        }
        
	},
    mounted: function(){
        this.createChart();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy: function(){
        window.removeEventListener('resize', this.onResize);
    }
}
</script>

<style scoped>
#sintesiImportiTabella1 tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
#sintesiImportiTabella2 tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.arrow-label {
  text-align: center;
}
</style>