<template>
    <v-container id="headerContainer5" class="pa-8">
            <v-row justify="center" align="center">
                <v-col cols="8">
                    <img v-if="imgHeader"  contain :src="getHeaderLogo()" id="logo_servizio" @error="() => {imgHeader = !imgHeader}"/>
                </v-col>
                <v-col cols="2">
                    <img :onerror="hideParent()" class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Mobile/ico-punto-domanda.svg')" @click="showDialogAiuto = true"/>
                </v-col>
                <v-col cols="2">
                    <img :onerror="hideParent()" class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Mobile/ico-omino.svg')" @click="showDialogProfilo = true"/>
                </v-col>
            </v-row>

            <v-dialog 
            transition="v-slide-x-transition"
              v-model="showDialogAiuto" 
            fullscreen >

           <v-card class="card_home_container" id="headerContainer5">
              <v-card class="head_mobile_fast_actionw-screen" id="head" >

                <v-row justify="center" align="center">
                    <v-col cols="4">
                          <img :onerror="hideParent()" class="dialog_img" width="10%"  :src="getImg('/images/bollettainterattiva/Template5/Mobile/icona-back.svg')"  @click="showDialogAiuto=false" />
                    </v-col>
                    <v-col cols="4">
                      <v-row>
                      <img :onerror="hide()" class="dialog_img" width="10%"  :src="getImg('/images/bollettainterattiva/Template5/Mobile/ico-domanda.svg')"   />
                        <p class="aiuto_title overflow-hidden" v-html='translateLabel("message.bolletta_interattiva_template5_mobile_help_title")'></p>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                    </v-col>
                </v-row>
            </v-card>
            <v-row>
                <p class="aiuto_title overflow-hidden" v-html='translateLabel("message.bolletta_interattiva_template5_mobile_help_text")'></p>
            </v-row>
            <v-row v-for="(comp, i) in ['help']"  :key="'component-' + i">
              <component :is="getModule(comp)" :documentData="documentData" :error="error" :translateLabel="translateLabel" :getImg="getImg"></component>
            </v-row>
            </v-card>
         </v-dialog>

           <v-dialog 
            transition="v-slide-x-transition"
            v-model="showDialogProfilo" 
            fullscreen >

           <v-card class="card_home_container" id="headerContainer5">
              <v-card class="head_mobile_fast_actionw-screen" id="head" >

                <v-row justify="center" align="center">
                    <v-col cols="4">
                          <img :onerror="hideParent()" class="dialog_img" width="10%"  :src="getImg('/images/bollettainterattiva/Template5/Mobile/icona-back.svg')"  @click="showDialogProfilo=false" />
                    </v-col>
                    <v-col cols="4">
                      <v-row>
                      <img :onerror="hide()" class="dialog_img" width="10%"  :src="getImg('/images/bollettainterattiva/Template5/Mobile/ico-omino.svg')"   />
                        <p class="profilo_title overflow-hidden" v-html='translateLabel("message.bolletta_interattiva_template5_mobile_profilo_title")'></p>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                    </v-col>
                </v-row>
            </v-card>
            <v-row v-for="(comp, i) in ['miei_dati_mio_contratto','area_riservata']"  :key="'component-' + i">
              <component :is="getModule(comp)" :documentData="documentData" :error="error" :translateLabel="translateLabel" :getImg="getImg"></component>
            </v-row>
            </v-card>
         </v-dialog>

      </v-container>
       
</template>

<script>
import { services } from '../../../scripts/services/serviceBuilder'
import BollettaInterattivaHelpTile5Dt from '../Desktop/BollettaInterattivaHelpTile5Dt.vue';
import BollettaInterattivaMieiDatiMioContratto5Dt from '../Desktop/BollettaInterattivaMieiDatiMioContratto5Dt.vue';
import BollettaInterattivaAreaRiservata5Dt from '../Desktop/BollettaInterattivaAreaRiservata5Dt.vue';
import utility from '../Core/utility';


export default ({
    mixins: [utility],
    components: {
        //Languages,
        BollettaInterattivaHelpTile5Dt,
        BollettaInterattivaMieiDatiMioContratto5Dt,
        BollettaInterattivaAreaRiservata5Dt
    },
    props:{
        getHeaderLogo: Function,
        locales: Array,
        cryptoId: {
            type: String,
            default: null
        },
        documentId: {
            type: String,
            default: null
        },
        checkLabel: {
            type: Function,
        },
        translateLabel: {
            type: Function,
        },
        getImg: {
            type: Function,
        },
        modules: {
        type: Object,
        default: null,
        },   
        error: {
        type: Number,
        default: null
        }, 
    },
    data() {
        return {
            bolletta_interattiva_esponi_descrizione_servizio_header:false,
            documentData:{},
            imgHeader: true,
            showDialogAiuto: false,
            showDialogProfilo: false,
        };
    },
    methods: {
            getModule(name){
      return this.modules[name];
    },
    },
    created: function () {
		var that = this;
        var requests = [];
        //let requests = that.cryptoId != null ? [services.apiCallerBollettaInterattiva.documentInfo(that.cryptoId)]: services.apiCallerBollettaInterattiva.documentInfoById(that.documentId);
        if(that.cryptoId != null)
            requests.push(services.apiCallerBollettaInterattiva.documentInfo(that.cryptoId));
        else
            requests.push(services.apiCallerBollettaInterattiva.documentInfoById(that.documentId));
        Promise.all(requests)
            .then(responses => {
                let obj = responses[0].data;
                
                that.documentData = obj;
                that.bolletta_interattiva_esponi_descrizione_servizio_header = Boolean(Number(that.documentData.bolletta_interattiva_esponi_descrizione_servizio_header));
            })
            .catch(error => {
                console.debug("Errore Header: " + error);
            });
        
    }
})
</script>
