<template>
  <v-container id="BollettaInterattivaAreaRiservata5Dt" class="pa-7">
    <v-row class="d-flex" justify="center" align="center">
      <v-card class="card_home_container">
        <v-row class="FirstContentBollettaInterattivaAreaRiservata5Dt d-flex" justify="center" align="center">
          <span class="fast_title">{{translateLabel('message.bolletta_interattiva_template5_area_riservata_title')}}</span>
        </v-row>   
        <v-row class="SecondContentBollettaInterattivaAreaRiservata5Dt d-flex" justify="center" align="center" >
          <span class="area_riservata_text"  v-html="translateLabel('message.bolletta_interattiva_template5_area_riservata_text')"></span>
        </v-row>                  
        <v-row class="ThirdContentBollettaInterattivaAreaRiservata5Dt d-flex" justify="center" align="center" >
          <v-btn
              class="bollettaInterattiva5_area_riservata_loginButton black--text"
                @click="gotoLogin()"
                x-large
                color="white"
                >
                {{$t('message.bolletta_interattiva_template5_area_riservata_login')}}
          </v-btn>  <!--  -->
          <v-btn
              class="bollettaInterattiva5_area_riservata_singinButton white--text"   
                @click="gotoSignin()"
                x-large
                color="black"
                >
                {{$t('message.bolletta_interattiva_template5_area_riservata_signin')}}
          </v-btn>
        </v-row>        
      </v-card>
    </v-row> 
  </v-container>
</template>

<script>

export default {

  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
        gotoLogin: function () {
              window.open('https://areaclienti.unoenergy.it/s/login/', '_blank');
        },
        gotoSignin: function () {
              window.open('https://areaclienti.unoenergy.it/s/login/SelfRegister', '_blank');
        },
  }

}
</script>

<style>

</style>