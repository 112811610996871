import AppSettingsService from './appSettingsService';

export default class ApiCaller {

    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    postUnoenergyPagamento(cryptoId, data){
        const url = this.webApiBaseUrl + "/api/api_unoenergy_pagamento/" + cryptoId; 
        return this.apiCallerHelper.callPost(url, data);
    }

    postUnoenergySegnaPagamento(cryptoId, data){
        const url = this.webApiBaseUrl + "/api/api_unoenergy_segna_pagamento/" + cryptoId;  //////////////  aqui
        return this.apiCallerHelper.callPost(url, data);
    }

}