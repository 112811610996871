<template>
    <v-container id="BollettaInterattivaFornituraConsumiCard5Dt">
        <v-row>
            <v-col cols="8" align="left">           
                <p class="text-h5 TitleFornituraConsumiCard5Dt">I miei consumi</p>
                <p class="text-h5 item_consumi_periodo">{{ item.consumi.periodo }}</p>    
            </v-col>
            <v-col cols="4" align="right">
                <img :onerror="hideParent()" :src="getImg('/images/bollettainterattiva/Template5/Core/img-grafico.svg')"/>
            </v-col>
        </v-row>
        <v-row id="RigaFornituraConsumiCard5Dt" class="FirstContentBollettaFornituraConsumiCard5Dt">
            <v-col cols="4" v-if="documentData.tipo_bolletta == 'GAS'" class="consumi_gas">
                <span class="text-h5 item_consumi_periodo" id="mobile_gas_OK" :style="{ display: isMobile ? '' : 'none' }">{{ item.consumi.consumo }}</span>
                <v-tooltip color="#195aa2" top>
                    <template v-slot:activator="{ on }">
                        <img :onerror="hide()" v-on="on" width="10%" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-search.svg')" />
                    </template>
                    <span class="white--text" >{{$t('message.bolletta_interattiva_template5_tooltip_search')}}</span>
                </v-tooltip>
                <p class="text-h5 item_consumi_periodo" id="fullscreen_gas_OK" :style="{ display: !isMobile ? '' : 'none' }" >{{ item.consumi.consumo }}</p>
                <div v-if="item.pdr">
                <span class="invoices_pde_label">{{$t('message.bolletta_interattiva_template5_pdr')}}</span>&nbsp;
                <span class="invoices_pde">{{ item.pdr}}</span>
                </div>
            </v-col>
            <v-col cols="4" v-if="documentData.tipo_bolletta != 'GAS'" class="consumi_luce">
                <span class="text-h5 item_consumi_periodo" id="mobile_luce_OK" :style="{ display: isMobile ? '' : 'none' }">{{ item.consumi.consumo }}</span>
                <v-tooltip color="#195aa2" top>
                    <template v-slot:activator="{ on }">
                        <img :onerror="hide()" v-on="on" width="10%" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-search.svg')" />
                    </template>
                    <span class="white--text" >{{$t('message.bolletta_interattiva_template5_tooltip_search')}}</span>
                </v-tooltip>
                <p class="text-h5 item_consumi_periodo" id="fullscreen_luce_OK" :style="{ display: !isMobile ? '' : 'none' }">{{ item.consumi.consumo }}</p>
                <div v-if="item.pod">
                <span class="invoices_pde_label">{{$t('message.bolletta_interattiva_template5_pod')}}</span>&nbsp;
                <span class="invoices_pde">{{ item.pod}}</span>
                </div>
            </v-col>
        </v-row>
        <v-row class="SecondContentBollettaFornituraConsumiCard5Dt" justify="left" align="left" v-if="documentData.tipo_bolletta == 'GAS'">
            <v-col cols="2" >
              <img :onerror="hideParent()" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-contatore.svg')" />
            </v-col>
            <v-col cols="10" class="">
                <span class="fornitura_consumi_text_gas" v-html="$t('message.bolletta_interattiva_template5_fornitura_consumi_text_gas')"></span>
            </v-col>
        </v-row>   
        <v-row v-else class="SecondContentBollettaFornituraConsumiCard5Dt" align="center" justify="center" >
            <span class="fornitura_consumi_text_luce" v-html="$t('message.bolletta_interattiva_template5_fornitura_consumi_text_luce')"></span>
        </v-row>   

        <v-row v-if="documentData.tipo_bolletta != 'GAS'" class="ThirdContentBollettaFornituraConsumiCardLuce5Dt" align="center" justify="center" >
            <v-col cols="4">
                <canvas id="annocorso-chart" ref="annocorso-chart" :height="annoCorsoHeight"> </canvas>
            </v-col>
            <v-col cols="4">
                <v-row class="image_inside_chart" v-if="checkPercentageLuce()">
                    <v-col v-if="imgPercentageArrow||imgPercentage" >
                        <img v-if="imgPercentageArrow" :src="(percentage > 0 ) ? getImg('/images/bollettainterattiva/Template5/Core/arrow-down.svg') : getImg('/images/bollettainterattiva/Template5/Core/arrow-up.svg')"  @error="() => {imgPercentageArrow = !imgPercentageArrow}" />
                        <img v-if="imgPercentage" :src="(percentage > 0 ) ? getImg('/images/bollettainterattiva/Template5/Core/ico-percent-positive.svg') : getImg('/images/bollettainterattiva/Template5/Core/ico-percent-negative.svg') "  @error="() => {imgPercentage = !imgPercentage}" />
                    </v-col>
                    <v-col v-if="percentage > 0">
                        <p class="fornitura_consumi_percentage_luce" v-html="percentage + '%'"></p>
                        <p class="fornitura_consumi_percentage_luce_text">{{translateLabel('message.fornitura_consumi_percentage_luce_text_complimenti')}}</p>
                        <p class="fornitura_consumi_percentage_luce_text_secondario">{{translateLabel('message.fornitura_consumi_percentage_luce_text_secondario_complimenti')}}</p>
                    </v-col>
                    <v-col v-else>
                        <p class="fornitura_consumi_percentage_luce_red" v-html="percentage + '%'"></p>
                        <p class="fornitura_consumi_percentage_luce_text_red">{{translateLabel('message.fornitura_consumi_percentage_luce_text_far_meglio')}}</p>
                        <p class="fornitura_consumi_percentage_luce_text_secondario">{{translateLabel('message.fornitura_consumi_percentage_luce_text_secondario_far_meglio')}}</p>
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-col>
            <v-col cols="4">
                <canvas id="annoprecedente-chart" ref="annoprecedente-chart" :height="annoPrecedenteHeight"> </canvas>
            </v-col>
        </v-row>   
        
        <v-row v-if="documentData.tipo_bolletta != 'GAS' && documentData.bolletta_interattiva_spesa_annua_importo==1" class="FourthContentBollettaFornituraConsumiCardLuce5Dt" align="center" justify="center" >
            <span class="fornitura_consumi_text_luce"> {{ item.testo_spesa_annua }}</span>
        </v-row>   

        <v-row class="ThirdContentBollettaFornituraConsumiCardGas5Dt" justify="center" align="center" v-if="documentData.tipo_bolletta == 'GAS'" >
            <v-col cols="6"  justify="center" align="center" >
                <img :onerror="hide()" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-point-datea-desktop.svg')" />
                <span class="fleft">{{firstValidDay()}}</span>
                <span class="fright">{{lastValidDay()}}</span>
            </v-col>
            <v-col cols="6">
                <v-row  justify="center" align="center" >
                    <img :onerror="hide()" width="5%" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-campana.svg')" />
                    <span class="download_promemoria"
                    @click="downloadCalendarMemo()">{{translateLabel('message.bolletta_interattiva_template5_fornitura_consumi_promemoria')}}</span>
                </v-row>
            </v-col>
        </v-row>  
        <v-row v-if="documentData.tipo_bolletta == 'GAS'" class="FourthContentBollettaFornituraConsumiCardGas5Dt" justify="center" align="center"  >
        <v-btn
              class="bollettaInterattiva3_downloadMemo white--text v-btn-shadow"
                @click="autolectura()"
                x-large
                color="black"
                >
                <span class="bollettaInterattiva5_autolettura_content"
                 v-html="$t('message.bolletta_interattiva_template5_autolettura_dialog_title')"></span> 
          </v-btn>
        </v-row>         
    </v-container>
</template>

<script>
//import { services } from '../scripts/services/serviceBuilder'
import moment from "moment";
import { createEvent} from 'ics';
import Chart from "chart.js";
import utility from '../Core/utility';


export default {
    name: 'BollettaInterattivaFornituraConsumiCard5Dt',
    mixins: [utility],
    data: function() {
    return {
        showDialog: false,
        percentage: 0,
        imgPercentage: true,
        imgPercentageArrow: true,
        annoCorsoVariable: null,
        annoCorsoHeight: 400,
        annoCorsoData: {
            type: "bar",
            data:{},
            options: {
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        boxWidth: 0,
                        usePointStyle: false
                    },
                    onClick: function() {}
                },
                scales: {
                    yAxes: [{
                        display:false,
                        ticks: {
                            beginAtZero: true,
                        }
                    }],
                    xAxes: [{
                        display:'auto'
                    }],
                }    
            },
        },
        annoPrecedenteVariable: null,
        annoPrecedenteHeight: 400,
        annoPrecedenteData: {
            type: "bar",
            data:{},
            options: {
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        boxWidth: 0,
                        usePointStyle: false
                    },
                    onClick: function() {}
                },
                scales: {
                    yAxes: [{
                        display:false,
                        ticks: {
                            beginAtZero: true,
                        }
                    }],
                    xAxes: [{
                        display:'auto'
                    }],
                }
            },    
            },
        }
    },
    props: {
        item: {},
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
            type: Function,
        },
        getImg: {
            type: Function,
        },
        //show: Boolean,
    },
    computed: {
        isMobile: function () {
           // console.log(this.$vuetify.breakpoint.mobile);
            return this.$vuetify.breakpoint.mobile;
        }
    },
    methods: {
        autolectura: function(){
        window.open('https://autolettura.unoenergy.it/gas')
    },
    getPercentageLuce:function(){
     if(this.checkPercentageLuce())
        this.percentage = Math.round(((this.annoPrecedenteData['tot']-this.annoCorsoData['tot']) / ((this.annoCorsoData['tot'] + this.annoPrecedenteData['tot'])/2) )*100);
     else
        this.percentage = 0;
    },
    checkPercentageLuce:function(){
     return (this.annoCorsoData['tot'] && this.annoPrecedenteData['tot'])
    },
    lastValidDay:function(){
     return moment().endOf('month').format('DD/MM');
    },
    firstValidDay:function(){
        return moment().endOf('month').subtract(5, "days").format('DD/MM');
    },
    downloadCalendarMemo: async function(){
            //Generate ICS file

            // Otteniamo la data corrente
            let currentDate = new Date();

            let lastDayOfCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

            if (lastDayOfCurrentMonth - currentDate.getDate() < 5) {
                currentDate.setMonth(currentDate.getMonth() + 1);
            }
            lastDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), lastDayOfCurrentMonth.getDate() - 5);

            console.log(currentDate);
            let alarm = [{ action: 'display', description: 'Reminder', trigger: { hours: 24, minutes: 0, before: true } }];
            const event = {
                productId:"UnoEnergy",
                start: [currentDate.getFullYear(), currentDate.getMonth()+1, currentDate.getDate(), 11, 0],
                title: "Effettua l'autolettura gas.",
                description: "Ricordati di effettuare l'autolettura gas.",
                status: 'CONFIRMED',
                recurrenceRule: 'FREQ=MONTHLY;BYMONTHDAY=-1;UNTIL='+currentDate.getFullYear()+(currentDate.getMonth()+1).toString().padStart(2, '0')+currentDate.getDate()+'T000000',
                alarms: alarm
            }
        

            const filename = 'Promemoria Autolettura.ics'
            const file = await new Promise((resolve, reject) => {
                createEvent(event, (error, value) => {
                if (error) {
                    reject(error)
                }
                
                resolve(new File([value], filename, { type: 'plain/text' }))
                })
            })
            const url = URL.createObjectURL(file);
            
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;
            
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            
            URL.revokeObjectURL(url);
        },
    initMethod: function () {
        let firstrun= (this.annoCorsoVariable ==null && this.annoPrecedenteVariable==null);


         if(this.checkPercentageLuce() && parseInt(this.item.storico_consumi[0].anno) < parseInt(this.item.storico_consumi[1].anno))
            this.item.storico_consumi = this.item.storico_consumi.reverse();

        this.resetChartsData();

        let midValues = Math.floor(this.item.storico_consumi.length / 2);

        //Popola anno corrente
        this.populateAnno(this.item.storico_consumi.slice(0, midValues), this.annoCorsoData);
        
        //Popola anno precedente
        this.populateAnno(this.item.storico_consumi.slice(midValues), this.annoPrecedenteData);
    
        this.setScale();

        this.getPercentageLuce();
        this.imgPercentageArrow=true;
        this.imgPercentage=true;

        if(!firstrun){
            this.annoCorsoVariable.update();
            this.annoPrecedenteVariable.update();
        }
    },
    resetChartsData: function(){
        this.annoCorsoData.data = {
                    labels:[],
                    datasets: [
                            {
                                label: '',
                                data: [],
                                backgroundColor: this.documentData.spesa_annua_color,
                                hoverOffset: 0,
                            },
                        ],
                };
        this.annoPrecedenteData.data = {
                    labels:[],
                    datasets: [
                        {
                            label : '',
                            data: [],
                            backgroundColor: this.documentData.spesa_annua_color,
                            hoverOffset: 0,
                        },
                    ],
                }; 
    },
    getDatasetsForPod: function(storicoConsumiArray,destinationData) {
            var datasets = [];

            const map = new Map(storicoConsumiArray.map(pos => [pos.mese + " " + pos.anno, pos]));
            const uniques = [...map.values()];

            let valtot = 0;
            if(uniques.length>0)
            {         
                let valuesDataset=[];

                for(let i = 0; i < uniques.length; i++) {
                    let valpush = 0;
                    let keysConsumi = Object.keys(uniques[i]).filter(k => k.startsWith("consumo_f"));
                    for(let a = 0; a < keysConsumi.length; a++) 
                    {
                        valpush += (Number(uniques[i][keysConsumi[a]]));
                    }
                    valuesDataset.push(valpush);
                    valtot += valpush;
                }
                datasets.push({
                label: valtot + ' kWh',
                data: valuesDataset,
                backgroundColor: this.documentData.spesa_annua_color,
                });  
            }
            destinationData['tot'] = valtot;
            return datasets;
        },
    setScale: function(){
        let scaleval;
        if(this.annoCorsoData.data && this.annoCorsoData.data.datasets && this.annoCorsoData.data.datasets.length>0)
            scaleval =  Math.max(...this.annoCorsoData.data.datasets[0].data);
        if(this.annoPrecedenteData.data && this.annoPrecedenteData.data.datasets && this.annoPrecedenteData.data.datasets.length>0
        && scaleval < Math.max(...this.annoPrecedenteData.data.datasets[0].data))
            scaleval = Math.max(...this.annoPrecedenteData.data.datasets[0].data);
        if(scaleval)
        {
        this.annoCorsoData.options.scales.yAxes[0].ticks.max = scaleval;  //Jul 1, 2020
        this.annoPrecedenteData.options.scales.yAxes[0].ticks.max = scaleval;  //Jul 1, 2020
        }
        
    },
    populateAnno: function(storicoConsumiArray,destinationData){
        let that = this;
        
        if(that.item.tipo_punto === "POD")
            {
                const map = new Map(storicoConsumiArray.map(pos => [pos.mese + " " + pos.anno, pos]));
                const uniques = [...map.values()];
                if(uniques.length>0){
                    destinationData.data.labels = uniques.map(x => {
                    return x.mese + " " + x.anno
                    });
                    destinationData.data.datasets = that.getDatasetsForPod(storicoConsumiArray,destinationData);
                }
            }
    },
    },
    beforeMount(){
        if(this.documentData.tipo_bolletta != 'GAS')
            this.initMethod();
    },
    mounted: function()
    {
        if(this.documentData.tipo_bolletta != 'GAS')
        {

        let that = this;
    
        that.$root.$on("changedFornituraSelected", this.initMethod);

        const ctxA = that.$refs["annocorso-chart"];
        that.annoCorsoVariable = new Chart(ctxA, that.annoCorsoData);
        const ctxB = that.$refs["annoprecedente-chart"];
        that.annoPrecedenteVariable = new Chart(ctxB, that.annoPrecedenteData);
        }
    },
}
</script>
