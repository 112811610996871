<template>
    <v-app>
      <v-skeleton-loader type="article" tile :loading="loading">
  
        
        <!--contenitore del main-->
  
        <v-main id="landingnexi" class="main">
          <!-- contenitore header principale -->
          <div>
            <Header5Dt :getHeaderLogo="getHeaderLogo" :locales="locales" :documentId="documentId" :cryptoId="cryptoId"></Header5Dt>
          </div>
          <v-container class="wrapWidth">
            <worldlineAnnullo v-if="annulloQueryString==='1'" :cryptoId="cryptoId" :documentId="documentId"></worldlineAnnullo>
            <worldlineEsito v-else :cryptoId="cryptoId" :documentId="documentId"></worldlineEsito>
          </v-container>
        </v-main>
        
      <!--contenitore del footer-->
      <v-footer class="footer" style="background-color: #373737;">
        <v-container justify="center">
          <v-row align="center">
           <p class="footer_info" align="center">
              Unoenergy S.p.A. - Sede Legale Via Giovanni Bensi, 10 - 20152 Milano (MI) - Cap. Soc. € 50.000.000 i.v. - R.E.A. MI/19025650 - P.I. e C.F. 01368720080  Sede operativa: Corso Felice Cavallotti 30 - 18038 Sanremo (IM) - Codice Destinatario: DRLMKW7
            </p>
          </v-row>
        </v-container>
      </v-footer>
      </v-skeleton-loader>
    </v-app>
  </template>
  <script>
  //import Languages from "../components/Languages.vue";
  import { services } from "../scripts/services/serviceBuilder";
  import Header5Dt from "../components/BollettaInterattiva5/Desktop/Header5Dt.vue";
  import worldlineEsito from "../components/worldlineEsito.vue";
  import worldlineAnnullo from "../components/worldlineAnnullo.vue";
  export default {
    name: "TemplateLandingWorldline",
    components: {
      worldlineEsito,
      Header5Dt,
      worldlineAnnullo
      //Languages,
    },
    props: {
    
    },
    data: () => ({
      cryptoId: null,
      documentId: null,
      error: null,
      loading: true,
      locales: [],
      annulloQueryString: null,
    }),
    computed: {
      isAuthenticated: function () {
        var result = this.getKeycloak(this.$parent);
        if (
          !result ||
          !Object.prototype.hasOwnProperty.call(result, "idTokenParsed")
        ) {
          return false;
        }
        return true;
      },
    },
    watch: {
        $route (to){
          this.loading = false;
          this.error = to.query.error;
          this.cryptoId = to.query.id;
          this.documentId = to.query.documentId;
        }
    },
    methods: {
      getKeycloak: function (node) {
        if (node == null) return null;
        if (node.$options.name != "App") return this.getKeycloak(node.$parent);
        return node.keycloak;
      },
      getHeaderLogo: function () {
        return "./" + location.hostname + "/images/bollettainterattiva/Template5/Core/UNOENERGY_2.svg";
      },
      getAreaClienti: function () {
        return "./" + location.hostname + "/images/bollettainterattiva/Template5/Core/servizio_clienti.svg";
      },
      getNumeroVerde: function () {
        return "./" + location.hostname + "/images/bollettainterattiva/Template5/Core/numero_verde.svg";
      },
      getSito: function () {
        return "./" + location.hostname + "/images/bollettainterattiva/Template5/Core/mouse.svg";
      },
      getIndirizzo: function () {
        return "./" + location.hostname + "/images/bollettainterattiva/Template5/Core/email.svg";
      },
      
    },
    created: function () {
      var that = this;
      var requests = [];
      requests.push(services.apiCallerLocale.list());
  
      Promise.all(requests).then((responses) => {
        if (
          !!responses[0].data &&
          Array.isArray(responses[0].data) &&
          responses[0].data.length > 1
        ) {
          that.locales = responses[0].data;
          that.$i18n.locale = that.$locale.value;
        }
      });
      
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('annullo');
      that.annulloQueryString = myParam;
    },
  };
  </script>
  
  <!-- <style>
  /* sfondo bolletta Duferco */
  #app {
      /* The image used */
      background-image: url("../../public/localhost/images/bollettainterattiva/dufercoBollettaInterattiva_Background.png") !important;
    
      /* Full height */
      height: 100% !important;
    
      /* Center and scale the image nicely */
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
  }
  </style> -->
  