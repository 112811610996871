<template>
  <v-container id="BollettaInterattivaMioContratto5Dt" class="pa-7">
    <v-card class="card_home_container" v-if="checkInArray('invoices_codice_cliente') || checkInArray('invoices_codice_contratto')">
      <h3 class="miei_dati_title">{{ $t('message.bolletta_interattiva_template5_mio_contratto_title') }}</h3>
      <v-row class="ContentBollettaInterattivaMioContratto5Dt d-flex" justify="left" align="left" >

        <div class="invoices_n_customer" v-if="checkInArray('invoices_codice_cliente')">
            <span class="invoices_n_customer">{{$t('message.bolletta_interattiva_template5_customer_code')}}</span>&nbsp;
            <p style="font-weight:bold;" class="invoices_n_customer">{{formatInvoice(elementInArray('invoices_codice_cliente'),documentData.currency_sigle,$t('message.document_date_not_available'))}}</p>
        </div>

        <div class="invoices_n_contract" id="invoices_n_contract_profilo" v-if="checkInArray('invoices_codice_contratto')">
            <span class="invoices_n_contract_label">{{$t('message.bolletta_interattiva_template5_mio_contratto_n_contract')}}</span>&nbsp;
            <span style="font-weight:bold;" class="invoices_n_contract">{{formatInvoice(elementInArray('invoices_codice_contratto'),documentData.currency_sigle,$t('message.document_date_not_available'))}}</span>
        </div>  
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import utility from '../Core/utility';

export default {
  mixins: [utility],
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
    checkInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            return Array.from(this.documentData.index_fields).some(item => item.label.toUpperCase() === field_name.toUpperCase());
        },
        /*valueInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            let field = Array.from(this.documentData.index_fields).find(item => item.label === field_name);
            return (field.value)?field.value:'';
        },*/
        elementInArray: function (field_name)
        {
            if(!this.documentData)
                return null;
            let field = Array.from(this.documentData.index_fields).find(item => item.label === field_name);
            return field;
        },
        getHolderAddress: function ()
        {
         return (this.documentData.holder_ind_riga2 || this.documentData.holder_ind_riga3 || this.documentData.holder_provincia) ? 
                (this.documentData.holder_ind_riga2 ? this.documentData.holder_ind_riga2 : '') + (this.documentData.holder_ind_riga3 ? ' ' + this.documentData.holder_ind_riga3 : '') + (this.documentData.holder_cap ? ' - ' + this.documentData.holder_cap : '') : + (this.documentData.holder_provincia ? ' ' + this.documentData.holder_provincia : '') ;
        },
  }

}
</script>

<style>

</style>