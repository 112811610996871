<template>
  <v-container id="BollettaInterattivaWebTile5Dt" class="pa-7">
    <v-row  justify="center" align="center">
      <v-card v-if="this.documentData.fatt_interattiva_data.riepilogo_doc.scelta_bolletta_web == '1'" class="card_home_container">
        <v-row class="FirstContentBollettaInterattivaWebTile5Dt " justify="left" align="left" >
            <v-col cols="2">
              <img :onerror="hideParent()" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-foglia.svg')" />
            </v-col>
            <v-col cols="10" class="">
                <span class="invoices_n_contract_label">{{translateLabel('message.bolletta_interattiva_template5_webText')}}</span>
            </v-col>
        </v-row>                     
        <v-row class="SecondContentBollettaInterattivaWebTile5Dt d-flex" justify="center" align="center" >
          <v-btn
              class="bollettaInterattiva5_webButton white--text v-btn-shadow"
                @click="scegliBollettaWeb()"
                x-large
                style="background-color: orange;"
                >
                <span class="bollettaInterattiva5_webButton_content"
                 v-html="$t('message.bolletta_interattiva_template5_web_button_label')"></span> 
          </v-btn>
        </v-row>
      </v-card>
    </v-row> 
  </v-container>
</template>

<script>
import utility from '../Core/utility';

export default {
  mixins: [utility],
  props: {
        documentData: {
          type: Object,
          default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
    scegliBollettaWeb: function () {
        window.open('https://variazioni-contrattuali.unoenergy.it/bolletta-digitale')      
    }
  }

}
</script>

<style>

</style>