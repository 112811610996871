<template>
    <v-container>
        <template v-if="$vuetify.breakpoint.mobile">
            <component v-for="(em, i) in enabledModules" :key="'component-' + i" :is="getModule(em)" :documentData="documentData" :error="error" :checkLabel="checkLabel"></component>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="4">
                    <component v-for="(em, i) in enabledModulesLeft" :key="'component-left-' + i" :is="getModule(em)" :documentData="documentData" :error="error" :checkLabel="checkLabel"></component>
                </v-col>
                <v-col cols="8" class="py-0">
                    <component v-for="(em, i) in enabledModulesRight" :key="'component-right-' + i" :is="getModule(em)" :documentData="documentData" :error="error" :checkLabel="checkLabel"></component>
                </v-col>
            </v-row>             
        </template>
    </v-container>
</template>

<script>
import BollettaInterattivaBody from './BollettaInterattivaBody.vue';
import BollettaInterattivaRiepilogoDoc from './BollettaInterattivaRiepilogoDoc.vue';
import BollettaInterattivaRiepilogoDoc3 from './BollettaInterattivaRiepilogoDoc3.vue';
import BollettaInterattivaChart from './BollettaInterattivaChart.vue';
import BollettaInterattivaChart2 from './BollettaInterattivaChart2.vue';
import BollettaInterattivaAvviso from './BollettaInterattivaAvviso.vue';
import BollettaInterattivaContatti3 from './BollettaInterattivaContatti3.vue';
import BollettaInterattivaDownload from './BollettaInterattivaDownload.vue';
import BollettaInterattivaDownload2 from './BollettaInterattivaDownload2.vue';
import BollettaInterattivaBanner from './BollettaInterattivaBanner.vue'
//import BollettaInterattivaFornitura from './BollettaInterattivaFornitura.vue';
import BollettaInterattivaDatiPagamento from './BollettaInterattivaDatiPagamento.vue';
import BollettaInterattivaCustomerCareEmail from './BollettaInterattivaCustomerCareEmail.vue';
import BollettaInterattivaDatiFornitura from './BollettaInterattivaDatiFornitura.vue';
import BollettaInterattivaProfiloDuferco from './BollettaInterattivaProfiloDuferco.vue';
import BollettaInterattivaAutoletturaUGM from './BollettaInterattivaAutoletturaUGM.vue';
import BollettaInterattivaDatiPagamento2 from './BollettaInterattivaDatiPagamento2.vue';
import BollettaInterattivaInsoluti from './BollettaInterattivaInsoluti.vue';
import Fornitura from './Fornitura.vue';
import ErrorPage from "../components/ErrorPage.vue";
import { services } from '../scripts/services/serviceBuilder'


export default ({
    components: {
        BollettaInterattivaBody,
        BollettaInterattivaRiepilogoDoc,
        BollettaInterattivaRiepilogoDoc3,
        BollettaInterattivaChart,
        BollettaInterattivaChart2,
        BollettaInterattivaAvviso,
        BollettaInterattivaContatti3,
        BollettaInterattivaDownload,
        BollettaInterattivaBanner,
        //BollettaInterattivaFornitura,
        Fornitura,
        BollettaInterattivaDatiPagamento,
        BollettaInterattivaDatiFornitura,
        BollettaInterattivaCustomerCareEmail,
        BollettaInterattivaProfiloDuferco,
        BollettaInterattivaAutoletturaUGM,
        BollettaInterattivaDatiPagamento2,
        BollettaInterattivaDownload2,
        BollettaInterattivaInsoluti,
        ErrorPage
    },
    props:{
      cryptoId: {
        type: String,
        default: null
      },
      documentId: {
        type: String,
        default: null
      },
      error: {
        type: Number,
        default: null
      },
      checkLabel: {
        type: Function,
      },
    },
    data() {
        return {
            enabledModules: [],
            enabledModulesLeft: [],
            enabledModulesRight: [],
            modules: {
                "riepilogo_doc": "BollettaInterattivaRiepilogoDoc",
                "riepilogo_doc3": "BollettaInterattivaRiepilogoDoc3",
                "sintesi_importi2": "BollettaInterattivaChart2",
                //"rate": "BollettaInterattivaBody",
                "info_e_contatti": "BollettaInterattivaContatti3",
                "scarica_pdf": "BollettaInterattivaDownload2",
                "banner_pubblicitario": "BollettaInterattivaBanner",
                "mancati_pagamenti": "BollettaInterattivaAvviso",
                "error_page": "ErrorPage",
                //"fornitura": "BollettaInterattivaFornitura",
                "fornitura_duferco": "Fornitura",
                "dati_pagamento": "BollettaInterattivaDatiPagamento",
                "customer_care": "BollettaInterattivaCustomerCareEmail",
                "dati_fornitura_duferco": "BollettaInterattivaDatiFornitura",
                "profilo_duferco": "BollettaInterattivaProfiloDuferco",
                "autolettura_ugm": "BollettaInterattivaAutoletturaUGM",
                "dati_pagamento2": "BollettaInterattivaDatiPagamento2",
                "insoluti": "BollettaInterattivaInsoluti",
            },
            overlayVisible: true,
            documentData: null
        }
    },
    computed: {
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    methods: {
        payBtnClicked: function(item) {
            console.log(item);
        },
        getModule(name){
            return this.modules[name];
        },
    },
    created: function () {
		var that = this;
        var requests = [];
        if(that.error == null && that.cryptoId != null) {
            requests.push(services.apiCallerBollettaInterattiva.modules());
            if(that.cryptoId != null)
                requests.push(services.apiCallerBollettaInterattiva.documentInfo(that.cryptoId));
            else if(that.documentId != null)
                requests.push(services.apiCallerBollettaInterattiva.documentInfoById(that.documentId));
            else {
                that.enabledModules = ['error_page'];
                return;
            }

            Promise.all(requests)
            .then(responses => {
                
                var obj = responses[1].data;
                obj.crypto = that.cryptoId;
                console.log("obj",obj);
                that.documentData = obj;

                if(responses[0].data){
                    if(!!responses[0].data['modules'] && Array.isArray(responses[0].data['modules'])){
                        that.enabledModules = responses[0].data['modules'];
                    }
                    if(!!responses[0].data['modules_left'] && Array.isArray(responses[0].data['modules_left'])){
                        that.enabledModulesLeft = responses[0].data['modules_left'];
                    }
                    if(!!responses[0].data['modules_right'] && Array.isArray(responses[0].data['modules_right'])){
                        that.enabledModulesRight = responses[0].data['modules_right'];
                    }
                }
               
            })
            .catch(error => {
                that.error = error.data;
                that.enabledModules = ['error_page'];
            });
        }
        else {
            that.enabledModules = ['error_page'];
        }
    }
})
</script>