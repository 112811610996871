<template>
  <v-container id="bollettaInterattivaInsoluti">
    <v-row no-gutters>
      <v-col sm="12">
          <div v-show="show">
            <v-card-text>
            <p class="text-h5">{{$t('message.bolletta_interattiva_insoluti_header')}}</p>
            <v-simple-table id="InsolutiTabella">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-left">N. Fattura</td>
                      <td class="text-left">Scadenza</td>
                      <td class="text-right">Importo</td>
                      <td class="text-right"></td>
                    </tr>
                
                    <tr v-for="(item,index) in insolutiValues" :key="index">
                        <td class="text-left">{{ item.value.numero_documento }}</td>
                        <td class="text-left">{{ item.value.data_scadenza }}</td>
                        <td class="text-right">{{ item.value.importo_originale }}</td>
                        <td class="text-right"><v-btn v-if="item.value.crypto"
                                                style="border-radius: 8px !important; max-width: 60%;"
                                                class="black--text"
                                                id="buttonPagaOraPagopa"
                                                @click="pagopaPagamento(item.value.crypto)">
                                                {{$t('message.button_pagaora_pagopa')}}
                                            </v-btn></td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            </v-card-text>
          </div> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


export default {
    name: 'bollettaInterattivaInsoluti',
    data: function() {
        return {
            show: false,
            insolutiValues: []          
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        }
    },
    computed: {

    },
    components: {
        
    },
    methods: {
        
        getPagopaLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/pagopa_logo.png";
        },
        getPaymentStatusIco: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/check_mark.svg";
        },
        loadInsolutiValues: function(){
            let that = this;
            let startingValue = that.documentData.fatt_interattiva_data.insoluti;
            for (let prop in startingValue) {
                that.insolutiValues.push({
                    key: prop,
                    value: startingValue[prop]
                });
            }
        },
        getImportoValue: function(value){
            if(value.includes('euro'))
                return value;
            return value + ' euro';
        },
        pagopaPagamento: function(value){
            let that = this;
            const url = that.documentData.bolletta_interattiva_pagaora_pagopa_link + '?act=portal&id=' + value;
            window.open(url, '_blank');
        },      
    },
	created: async function () {
        let that = this;
        
        that.loadInsolutiValues();

        
        if(that.insolutiValues.length > 0){
            that.show = true;
        }
	},
    mounted: function(){  
    }
}
</script>
