<template>
  <v-container id="BollettaInterattivaFastActionTile5Dt" class="pa-7">
    <v-row  justify="center" align="center"  :class="isMobile ? 'monocol Mobile ContentBollettaInterattivaFastActionTile5Dt' : 'col Desktop ContentBollettaInterattivaFastActionTile5Dt'">

      <v-card class="card_fast_action_container" v-if="documentData.fatt_interattiva_data.riepilogo_doc.paga_ora == '1'">
        <v-row class="FirstContentBollettaInterattivaFastAction5Dt " justify="center" align="center" @click="pagaOra()">
           <v-col cols="10" justify="center" align="center">
              <p class="fast_title">{{translateLabel('message.bolletta_interattiva_template5_fast_actioins_paga_ora')}}</p>
           </v-col>
            <v-col cols="2" justify="center" align="center">
              <img :onerror="hideParent()" width="100%"  class=".float-right"
                :src="getImg('/images/bollettainterattiva/Template5/Core/ico-paga-subito.svg')"/>
            </v-col>
        </v-row> 
      </v-card>  
      
      <v-card class="card_fast_action_container" v-if="documentData.fatt_interattiva_data.riepilogo_doc.domiciliazione_bancaria == '1'">
        <v-row class="FirstContentBollettaInterattivaFastAction5Dt " justify="center" align="center" @click="attivaOra()">
           <v-col cols="10" justify="center" align="center">
              <p class="fast_title">{{translateLabel('message.bolletta_interattiva_template5_fast_actioins_domiciliazione_bancaria')}}</p>
           </v-col>
            <v-col cols="2" justify="center" align="center">
              <img :onerror="hideParent()" width="100%"  class=".float-right"
                :src="getImg('/images/bollettainterattiva/Template5/Core/ico-domiciliazione.svg')"/>
            </v-col>
        </v-row> 
      </v-card>

      <v-card class="card_fast_action_container">
        <v-row class="FirstContentBollettaInterattivaFastAction5Dt " justify="center" align="center" @click="downloadFile">
           <v-col cols="10" justify="center" align="center">
              <p class="fast_title">{{translateLabel('message.bolletta_interattiva_template5_fast_actioins_download_invoice')}}</p>
           </v-col>
            <v-col cols="2" justify="center" align="center">
              <img :onerror="hideParent()" width="100%"  class=".float-right"
                :src="getImg('/images/bollettainterattiva/Template5/Core/ico-scarica-bollettino.svg')"/>
            </v-col>
        </v-row> 
      </v-card>     

      <v-card class="card_fast_action_container" v-if="documentData.tipo_bolletta == 'GAS'">
        <v-row class="FirstContentBollettaInterattivaFastAction5Dt " justify="center" align="center" @click="effettuaAutolettura()">
           <v-col cols="10" justify="center" align="center">
              <p class="fast_title">{{translateLabel('message.bolletta_interattiva_template5_fast_actioins_autolettura')}}</p>
           </v-col>
            <v-col cols="2" justify="center" align="center">
              <img :onerror="hideParent()" width="100%"  class=".float-right"
                :src="getImg('/images/bollettainterattiva/Template5/Core/ico-contatore.svg')"/>
            </v-col>
        </v-row> 
      </v-card> 

      <v-card class="card_fast_action_container" v-if="documentData.fatt_interattiva_data.riepilogo_doc.paga_ora == '1' && documentData.document_payment_expiry">
        <v-row class="FirstContentBollettaInterattivaFastAction5Dt " justify="center" align="center" @click="scaricaPromemoria()">
           <v-col cols="10" justify="center" align="center">
              <p class="fast_title">{{translateLabel('message.bolletta_interattiva_template5_fast_actioins_promemoria')}}</p>
           </v-col>
            <v-col cols="2" justify="center" align="center">
              <img :onerror="hideParent()" width="100%"  class=".float-right"
                :src="getImg('/images/bollettainterattiva/Template5/Core/ico-scarica-promemoria.svg')"/>
            </v-col>
        </v-row> 
      </v-card> 

         <!-- <v-dialog v-if="showDialog"
          transition="dialog-top-transition"
          v-model="showDialog" width="50%">      
            <v-card  class="card_home_container autolettura_dialog_card">
                  <v-card-title class="text-h6">                        
                      <v-row class="TitleDialog d-flex" justify="center" align="left" >
                        <v-col cols="1">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-contatore2.svg')" />
                        </v-col>
                        <v-col cols="9" class="">
                            <span class="dialog_title">{{translateLabel('message.bolletta_interattiva_template5_autolettura_dialog_title')}}</span>
                        </v-col>
                        <v-col cols="1" class=".float-end">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-exit.svg')"  @click="showDialog = false" />
                        </v-col>
                      </v-row>
                  </v-card-title>

                <v-card-text class="text-subtitle-1 pa-5 ">

                      <v-row class="FirstTextDialog d-flex" justify="center" align="left" >
                        <v-col cols="6" class="">
                          <p class="autoletturatile_dialog_text overflow-hidden" v-html='translateLabel("message.bolletta_interattiva_template5_autolettura_dialog_text")'></p>
                        </v-col>
                        <v-col cols="6">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/autolettura-image.svg')" />
                        </v-col>
                      </v-row>
                      <v-form
                          ref="form"
                          v-model="valid"
                        >
                          <v-row >
                          <v-text-field 
                          v-model="lettura_val" outlined
                          :rules="[v=> checkAutolettura(v)]"
                          >
                          </v-text-field>
                      </v-row>
                      </v-form>

                      <v-row>
                        <v-btn
                            style="border-radius: 8px !important;"
                            class="dialog_autolettura_save_button" 
                            @click="Save()"                         
                            >
                            {{$t('message.bolletta_interattiva_template5_autolettura_save')}}          
                        </v-btn>
                      </v-row>

                </v-card-text>
              </v-card>
          </v-dialog> -->
   
     <!--  <BollettaInterattivaAutoletturaDialog5Dt :showDialog="showDialog" @close="showDialog=false" :translateLabel="translateLabel" :getImg="getImg"/> --> 

    </v-row> 
  </v-container>
</template>

<script>
import { createEvent} from 'ics';
import updateToken from '../../../middleware/update-token';
import Vue from "vue";
import AppSettingsService from '../../../scripts/services/appSettingsService';
import { services } from '../../../scripts/services/serviceBuilder'
/* import BollettaInterattivaAutoletturaDialog5Dt from './BollettaInterattivaAutoletturaDialog5Dt.vue'; */
import utility from '../Core/utility';

export default {
  mixins: [utility],
 data() {
    return {
        showDialog : false
        }
  },
  components: {
    /* BollettaInterattivaAutoletturaDialog5Dt */
  },
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  computed: {
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        }
    },
  methods:{
        downloadFile: function() {
        var that = this;

        services.apiCallerMedia.download(that.documentData.detail)
          .then(response =>  {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'file.pdf');
              document.body.appendChild(link);
              link.click();
          })               
          .catch(error => {
              console.log(error);
          });
        },
        effettuaAutolettura: function(){
          window.open('https://autolettura.unoenergy.it/gas')
        },
        attivaOra: function(){
          window.open('https://variazioni-contrattuali.unoenergy.it/domiciliazione-bancaria')
        },
        pagaOra: function(){
            let that = this
            let requests = [];

            updateToken(Vue.prototype.$isAuthenticated.value);

            let pagaoraObj = {
                    success_url: window.location.origin + '/worldlineEsito?id=' + that.documentData.crypto + '&annullo=0',
                    error_url: window.location.origin + '/worldlineAnnullo?id=' + that.documentData.crypto + '&annullo=1',
                    callback_url: AppSettingsService.getSettings().worldlineS2sUrlpost + '/' + that.documentData.crypto + '/' + btoa(window.location.hostname)
            }
            
            requests.push(services.apiCallerBollettaInterattivaUnoenergy.postUnoenergyPagamento(that.documentData.crypto, pagaoraObj));
            
            Promise.all(requests).then((responses) => {                
                if(responses === null || responses[0].data == "ko"){
                    //Error
                    that.showWorldlineErrorDialog = true;
                    //console.log("KO");  ///////////////
                }else{
                    //Ok 
                    
                    //console.log(responses[0].data); ////////////////////////
                    window.location.href = responses[0].data;
                }
            }).catch(() => {
                that.showWorldlineErrorDialog = true;
                //console.log("ko catch");   ////////////////////
            });
        },
        scaricaPromemoria: async function(){
            //Generate ICS file
            let data_scadenza = new Date(this.documentData.dati_pagamento_fields.doc_payment_expiry.value); 
            console.log(data_scadenza);
            let alarm = [{ action: 'display', description: 'Reminder', trigger: { hours: 24, minutes: 0, before: true } }];
            const event = {
                productId:"UnoEnergy",
                start: [data_scadenza.getFullYear(), data_scadenza.getMonth()+1, data_scadenza.getDate(), 11, 0],
                title: "Pagamento bolletta",
                description: "Effettua il pagamento della bolletta",
                status: 'CONFIRMED',
                //recurrenceRule: 'FREQ=MONTHLY;BYMONTHDAY=-1;UNTIL='+data_scadenza.getFullYear()+(data_scadenza.getMonth()+1).toString().padStart(2, '0')+data_scadenza.getDate()+'T000000',
                alarms: alarm
            }
        

            const filename = 'Scadenza Bolletta.ics'
            const file = await new Promise((resolve, reject) => {
                createEvent(event, (error, value) => {
                if (error) {
                    reject(error)
                }
                
                resolve(new File([value], filename, { type: 'plain/text' }))
                })
            })
            const url = URL.createObjectURL(file);
            
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;
            
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            
            URL.revokeObjectURL(url);

        }
  }

}
</script>

<style>

</style>