export default {
    methods: {
      hideParent() {
        return "this.parentNode.style.display='none'";
      },
      hide() {
        return "this.style.display='none'";
      },
      formatInvoice(element,currency_sigle,msg)
      {
        if(element.format)
          switch (element.format) {
            case 'number':
              return this.$options.filters.formatNumber(element.value,element.format_show);
            case 'amount':
              return this.$options.filters.formatAmount(element.value,element.format_show, currency_sigle);
            case 'date':
              return this.$options.filters.formatDate(element.value,element.format_show, msg);
            default:
              return element.value;
          }
        else
          return element.value;
      }
    }
  };