import Vue from 'vue';
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vuetify from './plugins/vuetify';
import authentication from './plugins/authentication';
import axios from 'axios';
import updateToken from './middleware/update-token';
import AppSettingsService from "./scripts/services/appSettingsService";
//import HostSettingsService from "./scripts/services/hostSettingsService";
import Keycloak from 'keycloak-js';
import cookieconsent from 'vue-cookieconsent-component'
import VueI18n from 'vue-i18n'
import * as ics from 'ics'
import FlagIcon from 'vue-flag-icon'


axios.interceptors.request.use(async config => {
	const token = await updateToken(Vue.prototype.$isAuthenticated.value);
	config.headers.common['Authorization'] = `Bearer ${token}`;
	config.headers['FromCrypto'] = Vue.prototype.$tokenFromCrypto.value;
	return config;
});

Vue.prototype.$locale = { value: 'it' };
Vue.prototype.$cod_client = { value: null };
Vue.prototype.$isAuthenticated = { value: false };
Vue.prototype.$tokenFromCrypto = { value: false };
Vue.prototype.$appSettings = AppSettingsService.getSettings();
//HostSettingsService.getSettings().then(res => Vue.prototype.$hostSettings = res);
console.log('Vue.prototype.$hostSettings: ', Vue.prototype.$hostSettings)

Vue.use(authentication);
Vue.use(FlagIcon);
Vue.component('cookie-consent', cookieconsent)

Vue.use(VueI18n)
Vue.use(ics)

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.filter('formatAmount', function (value, formatShow, currencySigle, abs) {	
	var postCurrencySigle = '';
	if (typeof currencySigle == 'undefined' || !currencySigle || currencySigle.length == 0){
		currencySigle = '€ ';
	}else if(currencySigle == 'euro'){
		currencySigle = '';
		postCurrencySigle = ' euro';
	}else if(currencySigle == 'post €'){
		currencySigle = '';
		postCurrencySigle = ' €';
	}

	if (value) {
		var floatNum = parseFloat(value.replace(',', '.').replace(' ', ''));
		if (abs == '1'){
			floatNum = Math.abs(floatNum);
		}
		return currencySigle + new Intl.NumberFormat('it-IT', {minimumFractionDigits: formatShow}).format(floatNum.toFixed(formatShow)) + postCurrencySigle;
	}

	return '';
});

Vue.filter('formatNumber', function (value, formatShow, abs) {

	if (value) {
		var floatNum = parseFloat(value.replace(',', '.').replace(' ', ''));
		if (abs == '1'){	
			floatNum = Math.abs(floatNum);
		}	
		return floatNum.toFixed(formatShow);
	}

	return '';
});

Vue.filter('formatDate', function (value, formatShow, alternativeText) {

	if (value && value.length > 0 && value != '0000-00-00') {

		// se non è una data vuol dire che è nel formato dd-mm-yyyy quinidi fa split
		var dt;
		if (Date.parse(value).toString() == "NaN") {
			var chr = value.indexOf("-") > -1 ? "-" : "/";
			var dtSplitted = value.split(chr);
			if (dtSplitted[2].length == 4)
				dt = new Date(dtSplitted[2], dtSplitted[1] - 1, dtSplitted[0]);
			else
				dt = new Date(dtSplitted[0], dtSplitted[1] - 1, dtSplitted[2]);
		}
		else {
			dt = new Date(value);
		}

		var dd = dt.getDate();
		var mm = dt.getMonth() + 1;
		var yyyy = dt.getFullYear();

		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}

		return formatShow.replace('m', mm).replace('Y', yyyy).replace('d', dd);
	}

	if (typeof alternativeText == 'undefined' || !alternativeText || alternativeText.length == 0)
		return '';

	return alternativeText;
});

let kcOptions = {
	url: AppSettingsService.getSettings().authServerUrl,
	realm: window.location.hostname,
	clientId: AppSettingsService.getSettings().authClientId
}

Vue.prototype.$keycloak = Keycloak(kcOptions);

Vue.$keycloak
	.init({ checkLoginIframe: false, flow: AppSettingsService.getSettings().authFlow })
	.then(() => {
		fetch("./" + location.hostname + "/locales/messages.json").then(response => response.json())
			.then((messages) => {
				const i18n = new VueI18n({
					locale: 'it', // set locale
					messages: messages, // set locale messages
				})
				new Vue({
					vuetify,
					router,
					store,
					i18n,
					render: h => h(App)
				}).$mount('#app');

				window.onfocus = () => {
					updateToken(Vue.prototype.$isAuthenticated.value);
				};
			});
	})

Vue.$keycloak.onTokenExpired = function () {
	console.debug('token expired ...');
	if (Vue.$keycloak.flow == 'implicit') {
		var logoutOptions = { redirectUri: window.location.origin };
		Vue.$keycloak.logout(logoutOptions);
	} else {
		updateToken(Vue.prototype.$isAuthenticated.value);
	}
};
