<template>
  <v-container id="MultiComponentSwitchContainer5Dt" class="pa-7">
      <template v-if="selectedComponents && selectedComponents.length>0">
         <v-dialog v-if="direction != null"
          :transition="(direction == 'top')?'dialog-top-transition':'v-slide-x-reverse-transition'"
          v-model="showDialog" 
            fullscreen >

           <v-card class="card_home_container" id="headerContainer5">
              <v-card class="head_mobile_fast_actionw-screen" id="head" >

                <v-row justify="center" align="center">
                    <v-col cols="4">
                          <img :onerror="hideParent()" class="dialog_img" width="10%"  :src="getImg('/images/bollettainterattiva/Template5/Mobile/icona-back.svg')"  @click="closeDialog ()
                          showDialog=false " />
                    </v-col>
                    <v-col cols="4">
                      <v-row>
                      <img  :onerror="hide()" class="dialog_img" width="10%"  :src="getImg('/images/bollettainterattiva/Template5/Mobile/ico-fulmine.svg')"   />
                      <span>Azioni Rapide</span>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                    </v-col>
                </v-row>
            </v-card>
            <v-row v-for="(comp, i) in selectedComponents"  :key="'component-' + i">
              <component :is="getModule(comp)" :documentData="documentData" :error="error" :translateLabel="translateLabel" :getImg="getImg"></component>
            </v-row>
            </v-card>
         </v-dialog>

        <v-container  v-if="direction == null || !isMobile()">
          <v-row v-for="(comp, i) in selectedComponents"  :key="'component-' + i">
                <component :is="getModule(comp)" :documentData="documentData" :error="error" :translateLabel="translateLabel" :getImg="getImg"></component>
          </v-row>
        </v-container>
      </template>
  </v-container>
</template>

<script>
import Bolletta_Interattiva_Riepilogo_Doc5Dt from './Bolletta_Interattiva_Riepilogo_Doc5Dt.vue';
import BollettaInterattivaWebTile5Dt from './BollettaInterattivaWebTile5Dt.vue';
import BollettaInterattivaInfoPagamento5Dt from './BollettaInterattivaInfoPagamento5Dt.vue';
import BollettaInterattivaWarning5Dt from './BollettaInterattivaWarning5Dt.vue';
import BollettaInterattivaDownload5Dt from './BollettaInterattivaDownload5Dt.vue';
import BollettaInterattivaFornituraCosti5Dt from './BollettaInterattivaFornituraCosti5Dt.vue';
import BollettaInterattivaAreaRiservata5Dt from './BollettaInterattivaAreaRiservata5Dt.vue';
import BollettaInterattivaFastActionTile5Dt from './BollettaInterattivaFastActionTile5Dt.vue';
import BollettaInterattivaHelpTile5Dt from './BollettaInterattivaHelpTile5Dt.vue';
import BollettaInterattivaMieiDatiMioContratto5Dt from './BollettaInterattivaMieiDatiMioContratto5Dt.vue';
import BollettaInterattivaFornituraConsumi5Dt from './BollettaInterattivaFornituraConsumi5Dt.vue';
import { mapState } from 'vuex';
import {store} from '../../../store/index.js'
import utility from '../Core/utility';


export default {
  mixins: [utility],
  components: {
       Bolletta_Interattiva_Riepilogo_Doc5Dt,
       BollettaInterattivaInfoPagamento5Dt,
       BollettaInterattivaWebTile5Dt,
       BollettaInterattivaWarning5Dt,
       BollettaInterattivaDownload5Dt,
       BollettaInterattivaFornituraCosti5Dt,
       BollettaInterattivaAreaRiservata5Dt,
       BollettaInterattivaFastActionTile5Dt,
       BollettaInterattivaHelpTile5Dt,
       BollettaInterattivaMieiDatiMioContratto5Dt,
       BollettaInterattivaFornituraConsumi5Dt
    },
    
  props: {
    documentData: {
      type: Object,
      default: null,
    },
    modules: {
      type: Object,
      default: null,
    },
        error: {
      type: Number,
      default: null
    },
    translateLabel: {
        type: Function,
    },
    getImg:  {
      type: Function,
    },
  },
  data() {
      return {
      selectedComponents : null,
      showDialog : false,
      direction : null,
      oldval : null
      }
  },
  mounted() {
    //if(!this.selectedComponents && this.switcherElements.length>0 ) 
    // this.selectedComponents = this.switcherElements[0]['comp'];
  },
  computed: mapState(['components']),

  

  created() {
     this.unwatch = this.$store.watch(
      (state, getters) => getters.direction,
      (newValue, oldValue) => {
        console.log(`Updating from ${oldValue} to ${newValue}`);
        this.direction = newValue;
        this.showDialog = newValue != null;

      },
    );

    this.unwatch = this.$store.watch(
      (state, getters) => getters.components,
      (newValue, oldValue) => {
        console.log(`Updating from ${oldValue} to ${newValue}`);
        this.refreshComponents(oldValue,newValue);
      },
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    closeDialog:function()
    {
      if(this.direction!=null && this.oldval!=null)
      {   
        console.debug(this.oldval);    
        store.commit('SET_DIRECTION', null);

         store.commit('SET_COMPONENTS', this.oldval);
         this.selectedComponents=this.oldval;
         this.direction=null;
         
      }
    }
    ,
     isMobile: function () {
        return this.$vuetify.breakpoint.mobile;
    },
    refreshComponents(oldValue,newValue){
      
      if(this.direction==null){
        console.debug('salvato nuovo valore');    
        this.oldval=newValue;
      }
      this.selectedComponents = newValue;
      window.scrollTo(0,0);
    }
    ,
    getModule(name){
      return this.modules[name];
    },
    getimgComponent(imageName) {
      return "./" + location.hostname + imageName;
    },
  },
};
</script>
<style scoped>
.cardmoduleTextSelected {
  color: white;
}
.cardmoduleText {
  color: black;
}
.moduleSelected {
  background: orange!important;
}
.module {
  background: white!important;
}
</style>