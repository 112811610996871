<template>
    <v-container id="Bolletta_Interattiva_Riepilogo_Doc5Dt" >
        <v-row class="MainRows">
            <v-col cols="12" id="wrap_col_12"> 
                <v-container class="wrapWidth ContainerBolletta_Interattiva_Riepilogo_Doc5Dt">
                    <v-row class="FirstContentBolletta_Interattiva_Riepilogo_Doc5Dt" align="center" v-if="!isMobile">
                        <v-col cols="6" id="col_name">
                            <div class="invoices_holderName" v-if="documentData.holder_name && checkInArray('invoices_holder_name')">
                                <h1 class="invoices_holderName_label">{{$t('message.bolletta_interattiva_template5_holder_name')}}</h1>&nbsp;
                                <h1 class="invoices_holderName">{{formatInvoice(elementInArray('invoices_holder_name'),documentData.currency_sigle,$t('message.document_date_not_available'))}}</h1>
                            </div>
                        </v-col>
                        <v-col cols="6" id="col_conto">  
                            <v-row class="tw-box-laterale tw-box-desktop" v-if="checkInArray('invoices_codice_contratto') || checkInArray('invoices_n_avviso') || checkInArray('invoices_data_emissione')">
                                <v-col cols="3">
                                    <img :onerror="hideParent()" :src="documentData.tipo_bolletta == 'GAS' ? getImg('/images/bollettainterattiva/Template5/Core/ico-gas.svg') : getImg('/images/bollettainterattiva/Template5/Core/ico-luce.svg')" />
                                </v-col>
                                <v-col cols="8" class="tw-80 tw-m1 tw-dettaglio-box-1">
                                    <div class="invoices_n_contract" v-if="checkInArray('invoices_codice_contratto')">
                                        <span class="invoices_n_contract_label">{{$t('message.bolletta_interattiva_template5_n_contract')}}</span>&nbsp;
                                        <span style="font-weight:bold;" class="invoices_n_contract">{{formatInvoice(elementInArray('invoices_codice_contratto'),documentData.currency_sigle,$t('message.document_date_not_available'))}}</span>
                                    </div>
                                    <div class="invoices_n_invoice" v-if="checkInArray('invoices_n_avviso')">
                                        <span class="invoices_n_invoice_label">{{$t('message.bolletta_interattiva_template5_n_invoice')}}</span>&nbsp;
                                        <span style="font-weight:bold;" class="invoices_n_invoice">{{formatInvoice(elementInArray('invoices_n_avviso'),documentData.currency_sigle,$t('message.document_date_not_available'))}}</span>
                                    </div>
                                    <div class="invoices_invoice_date"  v-if="checkInArray('invoices_data_emissione')">
                                        <span class="invoices_invoice_date_label">{{$t('message.bolletta_interattiva_template5_invoice_date')}}</span>&nbsp;
                                        <span style="font-weight:bold;" class="invoices_invoice_date">{{documentData.document_date}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="FirstContentBolletta_Interattiva_Riepilogo_Doc5Dt Mobile"   v-else>
                        <v-row id="col_conto_mov" class="tw-box-laterale tw-box-desktop" justify="center" align="center" v-if="checkInArray('invoices_codice_contratto') || checkInArray('invoices_n_avviso') || checkInArray('invoices_data_emissione')">
                            <v-col cols="2"  >
                                <img  id="col_icon_mov" :onerror="hideParent()" :src="documentData.tipo_bolletta == 'GAS' ? getImg('/images/bollettainterattiva/Template5/Core/ico-gas.svg') : getImg('/images/bollettainterattiva/Template5/Core/ico-luce.svg')" />
                            </v-col>
                            <v-col cols="8" class="tw-80 tw-m1 tw-dettaglio-box-1">
                                <div class="invoices_n_contract" v-if="checkInArray('invoices_codice_contratto')">
                                    <span class="invoices_n_contract_label">{{$t('message.bolletta_interattiva_template5_n_contract')}}</span>&nbsp;
                                    <span style="font-weight:bold;" class="invoices_n_contract">{{formatInvoice(elementInArray('invoices_codice_contratto'),documentData.currency_sigle,$t('message.document_date_not_available'))}}</span>
                                </div>
                                <div class="invoices_n_invoice" v-if="checkInArray('invoices_n_avviso')">
                                    <span class="invoices_n_invoice_label">{{$t('message.bolletta_interattiva_template5_n_invoice')}}</span>&nbsp;
                                    <span style="font-weight:bold;" class="invoices_n_invoice">{{formatInvoice(elementInArray('invoices_n_avviso'),documentData.currency_sigle,$t('message.document_date_not_available'))}}</span>
                                </div>
                                <div class="invoices_invoice_date"  v-if="checkInArray('invoices_data_emissione')">
                                    <span class="invoices_invoice_date_label">{{$t('message.bolletta_interattiva_template5_invoice_date')}}</span>&nbsp;
                                    <span style="font-weight:bold;" class="invoices_invoice_date">{{documentData.document_date}}</span>
                                </div>
                            </v-col>
                            <v-col cols="2">
                                <img id="img_bolletta_mobile" :onerror="hideParent()" :src="documentData.tipo_bolletta == 'GAS' ? getImg('/images/bollettainterattiva/Template5/Core/bg-gas.svg') : getImg('/images/bollettainterattiva/Template5/Core/bg-luce.svg')"/>
                            </v-col>
                        </v-row>
                        <div class="invoices_holderName" id="invoices_holderName_div" v-if="documentData.holder_name && checkInArray('invoices_holder_name')">
                            <h1 class="invoices_holderName_label">{{$t('message.bolletta_interattiva_template5_holder_name')}}</h1>&nbsp;
                            <h1 class="invoices_holderName">{{formatInvoice(elementInArray('invoices_holder_name'),documentData.currency_sigle,$t('message.document_date_not_available'))}}</h1>
                        </div>
                    </v-row> 
                    <v-row  id="row_address_codice" align="center" :class="isMobile?  ' monocol Mobile SecondContentBolletta_Interattiva_Riepilogo_Doc5Dt' : 'col DESKTOP SecondContentBolletta_Interattiva_Riepilogo_Doc5Dt'" >
                        <v-col cols="6" id="col_address">  
                            <v-row class="tw-box-laterale tw-box-desktop" v-if="checkHolderAddress()">
                                <v-col :cols="!isMobile ? '3' : '2' ">
                                    <img :onerror="hideParent()" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-pin.svg')"/>
                                </v-col>
                                <v-col cols="8" >
                                    <div class="invoices_address" >
                                        <div class="invoices_address_label">{{$t('message.bolletta_interattiva_template5_address')}}</div>
                                        <div v-for="(fornit, i) in documentData.fatt_interattiva_data.fornitura" :key="'fornitura-' + i">
                                            <div v-for="(indir, j) in fornit.indirizzo" :key="'indirizzo-' + j">
                                                <div style="font-weight:bold;" class="invoices_address">{{indir}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" id="col_codice">  
                            <v-row class="tw-box-laterale tw-box-desktop" v-if="checkInArray('invoices_codice_cliente')">
                                <v-col :cols="!isMobile ? '3' : '2' " >
                                    <img :onerror="hideParent()" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-codice-utenza.svg')"/>
                                </v-col>
                                <v-col cols="8" >
                                <div class="invoices_n_customer">
                                        <span class="invoices_n_customer">{{$t('message.bolletta_interattiva_template5_customer_code')}}</span>&nbsp;
                                        <span style="font-weight:bold;" class="invoices_n_customer">{{formatInvoice(elementInArray('invoices_codice_cliente'),documentData.currency_sigle,$t('message.document_date_not_available'))}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row  v-if="!isMobile">
                        <v-col cols="2">
                            <img id="img_bolletta" :onerror="hideParent()" :src="documentData.tipo_bolletta == 'GAS' ? getImg('/images/bollettainterattiva/Template5/Core/bg-gas.svg') : getImg('/images/bollettainterattiva/Template5/Core/bg-luce.svg')"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col> 
        </v-row>    
    </v-container>
</template>

<script>

import utility from '../Core/utility';

export default ({
    mixins: [utility],
    data() {
        return {
            imageExists: true,
            userImageExists: true
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    computed: { 
        holderCapLocProv() {
            if (this.documentData.holder_cap || this.documentData.holder_localita || this.documentData.holder_provincia) {
                return this.documentData.holder_cap + ' ' + this.documentData.holder_localita + ' (' + this.documentData.holder_provincia + ')'
            }
            return null;
        }, 
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    methods: {
        getServiceLogo: function() {
            if (this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio) {
                return './' + location.hostname + '/images/bollettainterattiva/' + this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio;
            }
            else {
                this.imageExists = false;
            }
        },
        getUserImage: function() {
            return './' + location.hostname + '/images/bollettainterattiva/ProfileIcon.png';
        },
        imageError: function() {
            this.imageExists = false;
        },
        userImageError: function() {
            this.userImageExists = false;
        },
        checkInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            return Array.from(this.documentData.index_fields).some(item => item.label.toUpperCase() === field_name.toUpperCase());
        },
        /*valueInArray: function (field_name)
        {
            if(!this.documentData)
                return null;
            let field = Array.from(this.documentData.index_fields).find(item => item.label === field_name);
            return (field.value)?field.value:'';
        },*/
        elementInArray: function (field_name)
        {
            if(!this.documentData)
                return null;
            let field = Array.from(this.documentData.index_fields).find(item => item.label === field_name);
            return field;
        },
        checkHolderAddress: function ()
        {
         return (this.getHolderAddress() != null || this.getHolderAddress2() != null);
        },
        getHolderAddress: function ()
        {
         return (this.documentData.holder_ind_riga2 ? this.documentData.holder_ind_riga2 + ' ' : '') 
                + (this.documentData.holder_ind_riga3 ? this.documentData.holder_ind_riga3 + ' ' : '') 
                + (this.documentData.holder_toponimo ? this.documentData.holder_toponimo + ' ' : '') 
                + (this.documentData.holder_cap ? '- ' + this.documentData.holder_cap : '');
        },
        getHolderAddress2: function ()
        {
         return (this.documentData.holder_localita ? this.documentData.holder_localita + ' ' : '') 
                + (this.documentData.holder_provincia ? this.documentData.holder_provincia : '');
        },
        getImg(imageName) {
            return "./" + location.hostname + imageName;
        },
    },
})
</script>

